import { getSectionProps } from '@/utils/blocks';
import type { LooseObject } from '~/types/generic';

export function useSectionProps() {
  return getSectionProps();
}

export function useContentRowProps() {
  return {
    row: {
      type: Object as PropType<LooseObject>,
      required: true,
    },
    rowIndex: {
      type: Number as PropType<number>,
      required: true,
    },
  };
}
