<template>
  <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M58.5109 0.0480937C77.1632 0.862116 91.1739 14.9881 102.761 29.6083C114.278 44.1391 125.073 61.0172 121.196 79.1403C117.271 97.4903 101.283 110.369 83.9419 117.591C67.8052 124.312 49.7958 123.166 34.1361 115.401C18.7281 107.76 7.675 94.0424 3.06953 77.4891C-1.84891 59.8109 -1.55538 40.4474 9.12994 25.522C20.4667 9.68681 39.0379 -0.80175 58.5109 0.0480937Z"
      fill="currentColor"
    />
  </svg>
</template>
