<template>
  <div
    class="process-cover-image-slide"
    :class="[
      { 'has-opacity': hasOpacity },
      hasOpacity && overlayColor ? `has-overlay-${overlayColor}` : '',
      hasOpacity && item.opacity_alpha ? `has-opacity-${item.opacity_alpha}` : '',
      'has-text-' + textColor,
    ]"
  >
    <div class="process-cover-image-slide__image">
      <picture-basic
        v-if="image"
        class="process-cover-image-slide__fig"
        v-bind="
          getPictureBindings(image, {
            contain: false,
            ratio: null,
            preset: 'cover',
          })
        "
      />
    </div>
    <div class="process-cover-image-slide__content">
      <span v-if="number" class="process-cover-image-slide__number">{{ number }}</span>
      <div v-if="title" class="process-cover-image-slide__title title" :class="textSize === 'large' ? 'is-2' : 'is-4'">
        {{ title }}
      </div>
      <hr v-if="title && (description || hasCta)" class="process-cover-image-slide__line" />
      <div class="process-cover-image-slide__info">
        <bard
          :content="description"
          :as-content="true"
          class="process-cover-image-slide__description"
          :class="textSize === 'large' ? 'is-lead' : ''"
        />
        <div v-if="hasCta" class="process-cover-image-slide__cta lead-label is-strong">
          <pt-button v-bind="getButtonBindings(item, 'button_')">
            {{ ctaLabel }}
          </pt-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import PictureBasic from '@/components/global/images/PictureBasic.vue';
import PtButton from '@/components/global/elements/PtButton.vue';
import { getSelectValue } from '@/utils/blocks';
import Bard from '~/components/content/Bard.vue';
import { useImages } from '~/composables/images';
import { useButtons } from '~/composables/buttons';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  number: {
    type: Number,
    default: null,
  },
  textSize: {
    type: String,
    default: 'default',
  },
});

const { getPictureBindings } = useImages();
const { getButtonBindings } = useButtons();

const title = computed(() => props.item.title || null);
const description = computed(() => props.item.description || null);
const image = computed(() => props.item.image || null);
const hasCta = computed(() => props.item.button_link && props.item.button_label);
const ctaLabel = computed(() => props.item.button_label);
const hasOpacity = computed(() => props.item.opacity);
const overlayColor = computed(() => getSelectValue(props.item.overlay_color, 'value', 'default'));
const textColor = computed(() => getSelectValue(props.item.text_color, 'value', 'light'));
</script>
