<template>
  <div class="block-team-members">
    <div class="block-team-members__inner container">
      <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
      <pt-section-header :title="section.title" :title-tag="titleTag" :description="section.description" />
      <div v-if="buttons.length" class="block-team-members__buttons has-marg-top-l">
        <pt-buttons centered>
          <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
            {{ button.label }}
          </pt-button>
        </pt-buttons>
      </div>
      <div v-if="teamMembers.length" class="block-team-members__items columns is-multiline has-marg-top-l is-variable is-6">
        <div v-for="(teamMember, i) in teamMembers" :key="i" class="column is-6-tablet is-4-desktop">
          <team-member-item :team-member="teamMember" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ensureArrayValue, getSectionButtons, getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useButtons } from '~/composables/buttons';
import { useBlob } from '~/composables/blob';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import TeamMemberItem from '~/components/global/components/team-members/TeamMemberItem.vue';

const props: SectionProps = defineProps(useSectionProps());

const { getButtonBindings } = useButtons();
const { getDisplayBlob, getBlobBindings } = useBlob();

const buttons = computed(() => getSectionButtons(props.section.buttons));
const teamMembers = computed(() => ensureArrayValue(props.section.team_members));
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
</script>
