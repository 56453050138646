<template>
  <div class="block-team-members-list">
    <div class="block-team-members-list__inner container is-narrower">
      <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
      <pt-section-header :title="section.title" :title-tag="titleTag" :description="section.description" class="has-marg-bottom-l" />
      <div v-if="teamMembers.length" class="block-team-members-list__items">
        <div v-for="(teamMember, i) in teamMembers" :key="i" class="block-team-members-list__item">
          <team-member-list-item :team-member="teamMember" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ensureArrayValue, getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import TeamMemberListItem from '~/components/global/components/team-members/TeamMemberListItem.vue';

const props: SectionProps = defineProps(useSectionProps());

const { getDisplayBlob, getBlobBindings } = useBlob();

const teamMembers = computed(() => ensureArrayValue(props.section.team_members));
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
</script>
