<template>
  <div class="block-process" :class="rootClasses">
    <div class="container" :class="{ 'is-narrower': isDefaultVariant }">
      <div class="block-process__inner">
        <pt-section-header
          :title="section.title"
          :description="section.description"
          :title-tag="titleTag"
          class="block-process__header has-marg-bottom-m"
          :slider-buttons="{
            enabled: isBoxedVariant,
            items: list,
            prevEl: `block-process-slider__btn-prev btn-prev-${section.id}`,
            nextEl: `block-process-slider__btn-next btn-next-${section.id}`,
          }"
        />
        <div v-show="list.length > 0">
          <process-cover-image-slider
            v-if="isCoverImageVariant"
            :items="list"
            :section-id="section.id"
            :background="background"
            :text-size="textSize"
            :display-numbers="displayNumbers"
          />
          <process-cover-image-h-slider
            v-if="isCoverImageHVariant"
            :items="list"
            :cover-image="section.cover_image"
            :section-id="section.id"
            :background="background"
            :display-numbers="displayNumbers"
            :text-size="textSize"
          />
          <process-list v-if="isDefaultVariant" class="block-process__list" :list="list" />
        </div>
      </div>
    </div>
    <pt-slider v-if="isBoxedVariant && list.length > 0" :name="section.id" :items="list">
      <template #default="item">
        <process-boxed-slide :item="{ ...item.item, number: item.number }" :count="list.length" :background="background" />
      </template>
    </pt-slider>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import ProcessList from '@/components/global/components/process/ProcessList.vue';
import PtSlider from '@/components/global/elements/PtSlider.vue';
import ProcessBoxedSlide from '@/components/global/components/process/ProcessBoxedSlide.vue';
import ProcessCoverImageSlider from '@/components/global/components/process/ProcessCoverImageSlider.vue';
import ProcessCoverImageHSlider from '@/components/global/components/process/ProcessCoverImageHSlider.vue';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();

const rootClasses = computed(() => {
  const classes = [];

  if (variant.value) {
    classes.push(`is-variant-${variant.value}`);
  }

  if (background.value) {
    classes.push(`has-bg-${background.value}-light`);
  }

  return classes;
});

const list = computed(() => props.section.list || []);

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));

const variant = computed(() => getSelectValue(props.section.variant));

const isBoxedVariant = computed(() => variant.value === 'boxed');
const isCoverImageVariant = computed(() => variant.value === 'cover-image');
const isCoverImageHVariant = computed(() => variant.value === 'cover-image-h');
const isDefaultVariant = computed(() => !isBoxedVariant.value && !isCoverImageVariant.value && !isCoverImageHVariant.value);
const background = computed(() => {
  const background = getSelectValue(props.section.background, 'value');
  return background && background !== 'none' ? background : '';
});

const displayNumbers = computed(() => getSelectValue(props.section.display_numbers, 'value', 'default'));

const textSize = computed(() => getSelectValue(props.section.text_size, 'value', 'default'));
</script>
