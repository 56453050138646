<template>
  <div class="block-title-text" :class="rootClasses">
    <div class="block-title-text__container container">
      <div class="block-title-text__inner">
        <div class="block-title-text__content">
          <component :is="titleTag" v-if="hasTitle" class="block-title-text__title" :class="titleClasses">
            {{ section.title }}
          </component>
          <bard
            :content="content"
            :as-content="false"
            wrap-links
            :wrap-text-span="displayType == 'highlight'"
            class="block-title-text__text"
            :class="textClasses"
          />
          <ul v-if="hasFeatures" class="block-title-text__features">
            <li v-for="(feature, index) in features" :key="`feature-${index}`" class="block-title-text__features-item">
              <span class="block-title-text__features-item-icon">
                <pt-icon-check />
              </span>
              <span class="block-title-text__features-item-label">{{ feature.feature }}</span>
            </li>
          </ul>
        </div>
        <div v-if="buttons.length" class="block-title-text__buttons">
          <pt-buttons :centered="align == 'centered'">
            <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
              {{ button.label }}
            </pt-button>
          </pt-buttons>
        </div>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import { ensureArrayValue, getSectionButtons, getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import PtIconCheck from '~/components/global/icons/PtIconCheck.vue';
import Bard from '~/components/content/Bard.vue';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import { useBlob } from '~/composables/blob';
import { useButtons } from '~/composables/buttons';

const props: SectionProps = defineProps(useSectionProps());

const { getButtonBindings } = useButtons();
const { getDisplayBlob, getBlobBindings } = useBlob();

const rootClasses = computed(() => {
  const classes = [];

  const bg = getSelectValue(props.section.background);
  const width = getSelectValue(props.section.content_width);

  if (bg && displayType.value === 'box') {
    classes.push('has-bg');
    classes.push(`has-bg-${bg}`);
    if (bg !== 'white_border') {
      classes.push('has-links-indigo');
    }
  }

  if (align.value) {
    classes.push(`is-${align.value}`);
  }

  if (width) {
    classes.push(`has-w-${width}`);
  }

  if (displayType.value) {
    classes.push(`is-d-${displayType.value}`);
  }

  return classes;
});

const titleClasses = computed(() => ['title', titleTag.value === 'h1' ? 'is-1' : 'is-2']);

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));

const textClasses = computed(() => {
  if (displayType.value === 'highlight') {
    return ['content', 'is-large', 'has-p-links'];
  }
  return ['content', 'is-lead', 'has-p-links'];
});

const align = computed(() => getSelectValue(props.section.align, 'value', 'centered'));

const displayType = computed(() => getSelectValue(props.section.display_type, 'value', 'default'));

const buttons = computed(() => getSectionButtons(props.section.buttons));

const content = computed(() => {
  if (isEmpty(props.section.content) || !isString(props.section.content)) {
    return props.section.content;
  }
  return props.section.content;
});

const features = computed(() => ensureArrayValue(props.section.features));

const hasTitle = computed(() => displayType.value !== 'highlight' && !isEmpty(props.section.title));

const hasFeatures = computed(() => {
  if (!props.section.display_features) {
    return false;
  }
  if (isEmpty(props.section.features)) {
    return false;
  }

  return displayType.value === 'default';
});
</script>
