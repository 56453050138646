<template>
  <div class="feature-slide" :class="getBgClass(item)">
    <template v-if="icon">
      <div class="feature-slide__icon">
        <pt-icon :size="60" :name="icon" />
      </div>
    </template>
    <h6 v-if="item.title" class="feature-slide__title lead-label is-strong has-marg-top-lg">
      {{ item.title }}
    </h6>
    <div v-if="item.description" class="feature-slide__description text-label has-marg-top-sm">
      <p>{{ item.description }}</p>
    </div>
    <div v-if="hasCta" class="feature-slide__cta lead-label is-strong has-marg-top-sm">
      <pt-button tag="a" :href="slashed(item.link)">
        {{ item.label }}
      </pt-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getSelectValue } from '@/utils/blocks';
import PtButton from '@/components/global/elements/PtButton.vue';
import { slashed } from '~/utils/url';
import PtIcon from '~/components/global/elements/PtIcon.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const icon = computed(() => getSelectValue(props.item.icon_select, 'slug'));
const hasCta = computed(() => props.item.has_cta && props.item.link && props.item.title);

const getBgClass = (item) => {
  const background = getSelectValue(item.background, 'value', 'peach');
  return [`has-bg-${background}-light`];
};
</script>
