<template>
  <div class="floating-links-ellipse">
    <floating-link-middle
      :image="background.image"
      :text="background.content"
      :link-types="linkTypes"
      :type="LINK_TYPES.ellipse"
      @hover-links="hoveredFilter = $event"
      @filter-links="onFilterLinks"
      v-on="$attrs"
    />
    <div ref="linksWrap" class="floating-links-ellipse__content">
      <floating-link
        v-for="(link, index) in links"
        ref="linksRefs"
        :key="`link-${link.title}-${index}-${link.type}`"
        :link="link"
        :class="[
          `floating-links-ellipse__link`,
          {
            'is-not-hovered': isFilter(hoveredFilter, link.type),
            'is-filtered': isFilter(filter, link.type),
          },
        ]"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useWindowSize, watchDebounced } from '@vueuse/core';
import { LINK_TYPES } from '@/constants/floating-links';
import FloatingLink from '~/components/global/components/floating-links/FloatingLink.vue';
import FloatingLinkMiddle from '~/components/global/components/floating-links/FloatingLinkMiddle.vue';

const props = defineProps({
  background: {
    type: Object,
    required: true,
  },
  links: {
    type: Array,
    required: true,
  },
  linkTypes: {
    type: Array,
    required: true,
  },
  filter: {
    type: [String, null],
    default: null,
  },
});

const hoveredFilter = ref(null);
const links = toRef(props, 'links');
const linksWrap = ref(null);
const linksRefs = ref([]);
const linksEls = computed(() => linksRefs.value?.map((ref) => ref.el ?? null)?.filter(Boolean) ?? []) as ComputedRef<any[]>;

onMounted(() => {
  nextTick(() => makeEllipse(linksWrap.value));
  window.addEventListener('resize', debounceResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceResize);
});

watch(links, () => {
  nextTick(() => makeEllipse(linksWrap.value));
});

const { width } = useWindowSize();

watchDebounced(
  width,
  () => {
    makeEllipse(linksWrap.value);
  },
  {
    debounce: 100,
  }
);

const makeEllipse = (wrap) => {
  // docs https://behnamazimi.github.io/ellipsis-html/
  if (!wrap) {
    return;
  }
  const XEnd = wrap.offsetWidth;
  const YEnd = wrap.offsetHeight;
  const linksLength = linksEls.value.length;

  linksEls.value.reverse().forEach((link, i) => {
    const halfItemHeight = link.offsetHeight / 2;
    const halfItemWidth = link.offsetWidth / 2;

    const index = ((Math.PI * i) / linksLength) * 2 + 2;

    let X = XEnd / 2 + (XEnd / 2) * Math.cos(index) - halfItemWidth;
    let Y = YEnd / 2 - (YEnd / 2) * Math.sin(index) - halfItemHeight;
    if (X < 0) {
      X += halfItemWidth + randomNumberBetween(-10, 10);
    }
    if (XEnd - link.offsetWidth <= X) {
      X -= halfItemWidth + randomNumberBetween(10, 20);
    }
    if (Y < 0) {
      Y += halfItemHeight + randomNumberBetween(-10, 10);
    }
    if (YEnd - link.offsetHeight <= Y) {
      Y -= halfItemHeight + randomNumberBetween(10, 20);
    }
    orderSet(linksLength, link, i);
    link.style.transform = `translate3d(${X}px, ${Y}px, 0)`;
  });
};

const debounceResize = debounce(function () {
  makeEllipse(linksWrap.value);
}, 100);

const randomNumberBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const isFilter = (filter, type) => {
  return filter !== null && filter !== type;
};

const orderSet = (length, link, index) => {
  const to = length / 2;
  if (index > 0 && index < to) {
    link.style.zIndex = `${index + 1}`;
  }
};

const emit = defineEmits(['filter-links']);
const onFilterLinks = (filter) => emit('filter-links', filter);
</script>
