<template>
  <div class="block-hero-simple">
    <div class="block-hero-simple__inner container">
      <div class="block-hero-simple__content">
        <component :is="titleTag" v-if="section.title" class="block-hero-simple__title title is-1">
          {{ section.title }}
        </component>
        <bard
          v-if="section.description"
          as-content
          primary-links
          :content="section.description"
          class="block-hero-simple__description has-marg-top-m is-lead"
        />
        <div class="block-hero-simple__buttons">
          <pt-buttons v-if="buttons.length" centered>
            <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
              {{ button.label }}
            </pt-button>
          </pt-buttons>
        </div>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { getSectionButtons, getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useButtons } from '~/composables/buttons';
import { useBlob } from '~/composables/blob';
import Bard from '~/components/content/Bard.vue';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';

const props: SectionProps = defineProps(useSectionProps());

const { getButtonBindings } = useButtons();
const { getDisplayBlob, getBlobBindings } = useBlob();

const buttons = computed(() => getSectionButtons(props.section.buttons));

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h1'));
</script>
