<template>
  <div class="block-careers-features">
    <div class="block-careers-features__inner container">
      <div class="block-careers-features__cols columns is-variable is-6-tablet is-8-desktop">
        <div class="block-careers-features__col is-images column is-6-tablet is-8-desktop">
          <div class="block-careers-features__images-grid">
            <span class="block-careers-features__images-row-label">{{ section.values_label }}</span>
            <div class="block-careers-features__images-row is-values">
              <div v-for="(item, index) in valuesGallery" :key="`gallery-${index}`" class="block-careers-features__image">
                <picture-basic v-bind="getPictureBindings(item, { contain: null, ratio: 1, preset: 'side' })" />
              </div>
            </div>
          </div>
          <div class="block-careers-features__images-grid">
            <span class="block-careers-features__images-row-label">{{ section.purpose_label }}</span>
            <div class="block-careers-features__images-row is-purpose">
              <div v-if="section.purpose_image" class="block-careers-features__image">
                <picture-basic v-bind="getPictureBindings(section.purpose_image, { contain: null, ratio: 0.5, preset: 'side-large' })" />
              </div>
            </div>
          </div>
        </div>
        <div class="block-careers-features__col is-text column is-6-tablet is-4-desktop">
          <div class="block-careers-features__text">
            <component :is="titleTag" v-if="section.title" class="block-careers-features__title title is-3">
              {{ section.title }}
            </component>
            <bard v-if="section.description" :as-content="true" :content="section.description" :primary-links="true" lead class="has-marg-top-m" />
          </div>
          <div class="block-careers-features__buttons has-marg-top-m">
            <pt-buttons v-if="buttons.length" centered tiny-full>
              <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
                {{ button.label }}
              </pt-button>
            </pt-buttons>
          </div>
        </div>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { ensureArrayValue, getSectionButtons, getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import { useButtons } from '~/composables/buttons';
import { useImages } from '~/composables/images';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import Bard from '~/components/content/Bard.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';

const props: SectionProps = defineProps(useSectionProps());

const { getDisplayBlob, getBlobBindings } = useBlob();
const { getButtonBindings } = useButtons();
const { getPictureBindings } = useImages();

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
const buttons = computed(() => getSectionButtons(props.section.buttons));
const valuesGallery = computed(() => ensureArrayValue(props.section.values_gallery));
</script>
