<template>
  <div class="content-row-text">
    <div class="content-row-text__inner">
      <bard :content="row.text" :as-content="true" :primary-links="primaryLinks" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ContentRowProps } from '~/types/components';
import { useContentRowProps } from '~/composables/blocks';
import Bard from '~/components/content/Bard.vue';

const props: ContentRowProps = defineProps(useContentRowProps());
const primaryLinks = computed(() => !props.row.secondary_links);
</script>
