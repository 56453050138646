<template>
  <div class="page-anchor">
    <a :href="href" class="page-anchor__link">
      {{ item.label }}
    </a>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const href = computed(() => `#${getSelectValue(props.item.anchor)}`);
</script>
