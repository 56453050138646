<template>
  <div class="pricing-tabbed">
    <div class="pricing-tabbed__slider-nav-wrap">
      <div class="pricing-tabbed__slider-nav">
        <client-only>
          <swiper
            slides-per-view="auto"
            :space-between="0"
            :loop="false"
            :modules="[Mousewheel, Navigation]"
            :mousewheel="{
              mousewheel: {
                enabled: true,
                forceToAxis: true,
              },
            }"
            :navigation="{
              prevEl: prevBtnRef,
              nextEl: nextBtnRef,
            }"
            @click="swiperClickHandler"
          >
            <swiper-slide v-for="(item, index) in pricingItems" :key="index" class="pricing-tabbed__slider-nav-slide">
              <button
                class="pricing-tabbed__slider-nav-item"
                :class="{ 'is-selected': item.title === activeTab }"
                @click="selectSearchContentType(item.title, index)"
              >
                {{ item.title }}
              </button>
            </swiper-slide>
          </swiper>
          <button ref="prevBtnRef" class="pricing-tabbed__slider-nav-btn pricing-tabbed__slider-nav-btn-prev">
            <pt-icon-chevron-right />
          </button>
          <button ref="nextBtnRef" class="pricing-tabbed__slider-nav-btn pricing-tabbed__slider-nav-btn-next">
            <pt-icon-chevron-right />
          </button>
        </client-only>
      </div>
    </div>
    <div class="pricing-tabbed__content">
      <client-only>
        <swiper
          slides-per-view="auto"
          :space-between="0"
          effect="fade"
          :allow-touch-move="false"
          :free-mode="false"
          :fade-effect="{
            crossFade: true,
          }"
          :modules="[EffectFade]"
          @swiper="swiperContentInit"
        >
          <swiper-slide v-for="(item, index) in pricingItems" :key="`${item}-${index}`" class="pricing-tabbed__content-slide">
            <pricing-tabbed-item :item="item" :active-tab="activeTab" :is-full="isFull" />
          </swiper-slide>
        </swiper>
      </client-only>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectFade, Mousewheel, Navigation } from 'swiper/modules';
import PricingTabbedItem from '~/components/global/components/pricing/PricingTabbedItem.vue';
import PtIconChevronRight from '~/components/global/icons/PtIconChevronRight.vue';
import type { LooseObject } from '~/types/generic';

const props = defineProps({
  pricingItems: {
    type: Array as () => LooseObject[],
    required: true,
  },
  isFull: {
    type: Boolean,
    default: false,
  },
});
const prevBtnRef = ref(null);
const nextBtnRef = ref(null);
const activeTab = ref(props.pricingItems?.[0]?.title ?? '');
const swiperContent = ref(null);

const swiperClickHandler = (swiper) => {
  swiper.slideTo(swiper.clickedIndex);
};

const selectSearchContentType = (t, index) => {
  activeTab.value = t;
  if (swiperContent.value) {
    swiperContent.value.slideTo(index);
  }
};

const swiperContentInit = (swiper) => {
  swiperContent.value = swiper;
};
</script>
