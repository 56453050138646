<template>
  <div v-if="isValid" class="block-iframe-form">
    <div class="block-iframe-form">
      <div class="block-iframe-form__inner">
        <iframe
          class="embed__if booking-iframe"
          allowfullscreen="yes"
          v-bind="formBindings"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
          scrolling="no"
          :src="calendarUrl"
        />
      </div>
      <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import { useBlob } from '~/composables/blob';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();

const formBindings = computed(() => {
  const bindings = {};
  if (calendarUrl.value) {
    bindings.calendarUrl = calendarUrl.value;
  }
  return bindings;
});

const calendarUrl = computed(() => props.section.calendar_url || null);

const isValid = computed(() => !!calendarUrl.value);
</script>
