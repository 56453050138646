<template>
  <component :is="rowComponent" :row="row" :row-index="rowIndex" :class="rootClasses" />
</template>

<script lang="ts" setup>
import ContentRowText from '~/components/content/content-rows/ContentRowText.vue';
import ContentRowTextColumns from '~/components/content/content-rows/ContentRowTextColumns.vue';
import ContentRowTextImage from '~/components/content/content-rows/ContentRowTextImage.vue';
import ContentRowImage from '~/components/content/content-rows/ContentRowImage.vue';
import ContentRowEmbed from '~/components/content/content-rows/ContentRowEmbed.vue';
import { useContentRowProps } from '~/composables/blocks';
import type { ContentRowProps } from '~/types/components';

const props: ContentRowProps = defineProps(useContentRowProps());

defineOptions({
  components: {
    ContentRowText,
    ContentRowTextColumns,
    ContentRowTextImage,
    ContentRowImage,
    ContentRowEmbed,
  },
});

const rowType = computed(() => props.row.type.split('_').join('-'));
const rowComponent = computed(() => `content-row-${rowType.value}`);
const rootClasses = computed(() => {
  const classes = ['content-row', `is-${rowType.value}`];
  if (props.rowIndex) {
    classes.push('has-marg-top-xl');
  }
  return classes;
});
</script>
