<template>
  <div class="block-featured-files container">
    <featured-block-header :title="section.title" :intro="section.intro" />

    <client-only>
      <ais-instant-search
        :index-name="filesIndex"
        :routing="undefined"
        :search-client="searchClient"
        :insights="true"
        :future="{
          preserveSharedStateOnUnmount: true,
        }"
      >
        <ais-configure :analytics="true" :hits-per-page.camel="8" :filters="predefinedFilters" :distinct="true" />

        <div
          class="block-featured__inner"
          :class="{
            'has-menu': hasMenu,
          }"
        >
          <categories-menu v-show="hasMenu">
            <ais-hierarchical-menu :attributes="['categories.lvl0', 'categories.lvl1']" :transform-items="transformItems" />
          </categories-menu>

          <div>
            <ais-state-results>
              <ais-hits>
                <template #default="{ items }">
                  <featured-files-list :items="items" />
                </template>
              </ais-hits>
            </ais-state-results>
          </div>
        </div>
      </ais-instant-search>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useNuxtApp, useRuntimeConfig } from '#app';
import { useGlobalsStore } from '@/stores/globals';
import { useAlgoliaFilters } from '@/composables/algolia-filters';
import FeaturedBlockHeader from '~/components/global/components/featured/FeaturedBlockHeader.vue';
import CategoriesMenu from '~/components/global/components/categories-menu/CategoriesMenu.vue';
import FeaturedFilesList from '~/components/global/components/featured/FeaturedFilesList.vue';
import type { SearchConfig } from '~/plugins/search';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';

const hasMenu = ref(false);
const props: SectionProps = defineProps(useSectionProps());
const globals = useGlobalsStore();
const config = useRuntimeConfig();
const { menuFilters } = useAlgoliaFilters();
const { client: searchClient } = useNuxtApp().$search as SearchConfig;
const predefinedFilters = computed(() => props.section.filters);
const filesIndex = computed(() => config.public.algoliaFilesIndex);
const searchCategoriesTree = computed(() => globals.searchSettings?.categories_tree);

function transformItems(items) {
  items = menuFilters(props.section.display_type?.value, items, props.section.filters, searchCategoriesTree.value);
  hasMenu.value = !props.section.hide_categories_filter && items?.length > 0;
  return items;
}
</script>
