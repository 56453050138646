<template>
  <div class="block-careers-gallery">
    <div class="block-careers-gallery__inner container">
      <div class="block-careers-gallery__row">
        <div class="block-careers-gallery__col">
          <bard :content="section.text_1" :as-content="true" lead />
        </div>
        <div class="block-careers-gallery__col">
          <picture-basic v-if="image1" v-bind="image1" />
        </div>
        <div class="block-careers-gallery__col">
          <picture-basic v-if="image2" v-bind="image2" />
        </div>
      </div>
      <div class="block-careers-gallery__row">
        <div class="block-careers-gallery__col">
          <picture-basic v-if="image3" v-bind="image3" />
        </div>
        <div class="block-careers-gallery__col">
          <picture-basic v-if="image4" v-bind="image4" />
        </div>
        <div class="block-careers-gallery__col">
          <bard :content="section.text_2" :as-content="true" lead />
        </div>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { ensureArrayValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import { useImages } from '~/composables/images';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import Bard from '~/components/content/Bard.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';

const props: SectionProps = defineProps(useSectionProps());

const { getDisplayBlob, getBlobBindings } = useBlob();
const { getPictureBindings } = useImages();

const gallery = computed(() => ensureArrayValue(props.section.gallery));

const getImage = (index: any) => {
  return gallery.value[index]
    ? getPictureBindings(gallery.value[index], {
        preset: index === 1 || index === 3 ? 'side-large' : 'pricing',
      })
    : null;
};

const image1 = computed(() => getImage(0));
const image2 = computed(() => getImage(1));
const image3 = computed(() => getImage(2));
const image4 = computed(() => getImage(3));
</script>
