<template>
  <div class="floating-link-middle" :class="`is-${type}`">
    <div class="floating-link-middle__inner">
      <div class="floating-link-middle__image">
        <picture-basic v-bind="getPictureBindings(image)" />
      </div>
      <bard class="floating-link-middle__text" :content="text" as-content />
    </div>
    <floating-links-filter :link-types="linkTypes" :type="type" v-on="$attrs" @filter-links="onFilterLinks" @hover-links="onHoverLinks" />
  </div>
</template>

<script lang="ts" setup>
import { LINK_TYPES } from '@/constants/floating-links';
import FloatingLinksFilter from '~/components/global/components/floating-links/FloatingLinksFilter.vue';
import Bard from '~/components/content/Bard.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import { useImages } from '~/composables/images';

defineProps({
  image: {
    type: Object,
    required: true,
  },
  text: {
    type: Array,
    required: true,
  },
  linkTypes: {
    type: Array,
    required: true,
  },
  type: {
    type: String,
    default: LINK_TYPES.ellipse,
  },
});

const { getPictureBindings } = useImages();
const emit = defineEmits(['filter-links', 'hover-links']);
const onFilterLinks = (filter) => emit('filter-links', filter);
const onHoverLinks = (hoveredFilter) => emit('hover-links', hoveredFilter);
</script>
