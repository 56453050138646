<template>
  <div ref="el" class="block-triage-tool">
    <div class="block-triage-tool__container container">
      <header class="block-triage-tool__header has-marg-bottom-l">
        <pt-section-header :title="section.title" :title-tag="titleTag" :title-size="titleSize" :description="section.description" />
      </header>
      <div class="block-triage-tool__tool">
        <triage-tool @reset="onReset" />
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import TriageTool from '~/components/global/components/triage-tool/TriageTool.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';

const props: SectionProps = defineProps(useSectionProps());

const { getDisplayBlob, getBlobBindings } = useBlob();

const el = useTemplateRef('el');
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
const titleSize = computed(() => titleTag.value.replace('h', ''));

const onReset = () => {
  nextTick(() => {
    if (!el.value) {
      return;
    }
    const top = el.value.getBoundingClientRect().top + window.scrollY - 100;
    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  });
};
</script>
