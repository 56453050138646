<template>
  <div class="feature-item">
    <template v-if="icon">
      <div class="feature-item__icon">
        <pt-blob-icon />
        <pt-icon :size="60" :name="icon" />
      </div>
    </template>
    <h6 v-if="item.title" class="feature-item__title lead-label is-strong">
      {{ item.title }}
    </h6>
    <div v-if="item.description" class="feature-item__description lead-label">
      <p>
        {{ item.description }}
      </p>
    </div>
    <div v-if="hasCta" class="feature-item__cta lead-label is-strong">
      <pt-button-link :to="item.link" type="secondary">
        {{ item.title }}
      </pt-button-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import type { LooseObject } from '~/types/generic';
import PtIcon from '~/components/global/elements/PtIcon.vue';
import PtButtonLink from '~/components/global/elements/PtButtonLink.vue';
import PtBlobIcon from '~/components/global/elements/PtBlobIcon.vue';

const props = defineProps({
  item: {
    type: Object as () => LooseObject,
    required: true,
  },
});

const icon = computed(() => getSelectValue(props.item.icon_select, 'slug'));
const hasCta = computed(() => props.item.has_cta && props.item.link && props.item.title);
</script>
