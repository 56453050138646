<template>
  <div class="block-extended-buttons">
    <div class="block-extended-buttons__inner container">
      <pt-section-header :title="section.title" :title-tag="titleTag" :description="section.description" :width="width" class="has-marg-bottom-l" />
      <div v-if="extendedButtons.length" class="block-extended-buttons__buttons">
        <pt-extended-buttons :width="width" :per-row="getExtendedButtonsPerRow(section.extended_buttons_per_row)" :buttons="extendedButtons" />
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { ensureArrayValue, getSelectValue } from '@/utils/blocks';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import PtExtendedButtons from '~/components/global/elements/PtExtendedButtons.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import { useExtendedButtons } from '~/composables/extended-buttons';

const props: SectionProps = defineProps(useSectionProps());

const { getDisplayBlob, getBlobBindings } = useBlob();
const { getExtendedButtonsPerRow, getExtendedButtonsWidth } = useExtendedButtons();

const extendedButtons = computed(() => ensureArrayValue(props.section.extended_buttons));
const width = computed(() => getExtendedButtonsWidth(props.section.extended_buttons_width));
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
</script>
