<template>
  <div class="block-images-text">
    <div class="block-images-text__inner container" :class="width === 'narrow' ? 'is-narrower' : 'is-narrow'">
      <div v-for="(block, index) in blocks" :key="`block-${index}`" class="block-images-text__block">
        <pt-image-text :block="block" />
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { ensureArrayValue, getSelectValue } from '@/utils/blocks';
import PtImageText from '~/components/global/elements/PtImageText.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import { useBlob } from '~/composables/blob';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();

const blocks = computed(() => ensureArrayValue(props.section.blocks));
const width = computed(() => getSelectValue(props.section.width, 'value', 'normal'));
</script>
