<template>
  <div class="process-cover-image-slider">
    <client-only>
      <div ref="innerRef" class="process-cover-image-slider__inner">
        <swiper
          :slides-per-view="1"
          direction="vertical"
          effect="cards"
          :grab-cursor="true"
          :space-between="0"
          :loop="false"
          :allow-touch-move="false"
          :cards-effect="{
            perSlideOffset: 4,
            perSlideRotate: 0,
          }"
          :pagination="{
            el: paginationRef,
            clickable: true,
          }"
          :navigation="{
            nextEl: nextBtnEl,
            prevEl: prevBtnEl,
          }"
          :breakpoints="{
            [BREAKPOINTS.desktop]: {
              allowTouchMove: true,
            },
          }"
          :modules="[EffectCards, Navigation, Pagination]"
          @swiper="swiperInit"
          @slide-change="slideChange"
        >
          <swiper-slide v-for="(item, index) in items" :key="index" class="process-cover-image-slider__slide">
            <process-cover-image-slide :item="item" :number="getNumberByIndex(index)" :text-size="textSize" />
          </swiper-slide>
        </swiper>
        <div v-show="items.length > 1" class="process-cover-image-slider__btns">
          <pt-slider-btn ref="prevBtnRef" side-to="bottom" class="process-cover-image-slider__btn-next" />
          <pt-slider-btn ref="nextBtnRef" side-to="top" class="process-cover-image-slider__btn-prev" />
        </div>
      </div>
      <div v-show="items.length > 1" ref="paginationRef" class="process-cover-image-slider__pagination" />
    </client-only>
  </div>
</template>
<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCards, Navigation, Pagination } from 'swiper/modules';
import ProcessCoverImageSlide from '@/components/global/components/process/ProcessCoverImageSlide.vue';
import PtSliderBtn from '@/components/global/elements/PtSliderBtn.vue';
import { BREAKPOINTS } from '@/constants/layout';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  textSize: {
    type: String,
    default: 'default',
  },
  background: {
    type: String,
    default: '',
  },
  displayNumbers: {
    type: String,
    default: 'default',
  },
});

const innerRef = ref(null);
const paginationRef = ref(null);
const prevBtnRef = ref(null);
const nextBtnRef = ref(null);
const prevBtnEl = computed(() => prevBtnRef?.value?.el ?? null);
const nextBtnEl = computed(() => nextBtnRef?.value?.el ?? null);

const swiperInit = (swiper: any) => {
  swiper.slides.forEach((slide, index) => {
    if (!slide.classList.contains('swiper-slide-active')) {
      slide.children[0].style.width = 100 - index * 3 + '%';
    }
  });
};

const slideChange = (swiper: any) => {
  const { slides, activeIndex } = swiper;
  const activeSlide = slides[activeIndex];
  const slider = innerRef.value;

  let count = 0;

  activeSlide.firstElementChild.style.width = 100 + '%';

  for (let i = 0; i < activeIndex; i++) {
    slides[i].firstElementChild.style.width = 100 - (activeIndex - i) * 3 + '%';
  }

  for (let i = activeIndex + 1; i < slides.length; i++) {
    count++;
    slides[i].firstElementChild.style.width = 100 - count * 3 + '%';
  }

  if (!slider) {
    return;
  }

  if (activeIndex === 1) {
    slider.style.marginTop = '30px';
  }

  if (activeIndex > 1) {
    slider.style.marginTop = '50px';
  }

  if (activeIndex === 0) {
    slider.style.marginTop = 0;
  }

  if (slides.length > 1) {
    if (activeIndex === slides.length - 1) {
      slider.style.marginBottom = '30px';
    } else {
      slider.style.marginBottom = '50px';
    }
  }
};

const getNumberByIndex = (index: any) => {
  switch (props.displayNumbers) {
    case 'none':
      return null;
    case 'second':
      return index === 0 ? null : index;
    case 'second-alt':
      return index === 0 ? null : index + 1;
    default:
      return index + 1;
  }
};
</script>
