<template>
  <div class="block-paragraph" :class="{ 'is-centered': section.centered }">
    <div class="block-paragraph__inner">
      <pt-section-header :title="section.title" :title-tag="titleTag" :description="section.description" />
      <div v-if="hasButton" class="block-paragraph__buttons">
        <pt-button v-bind="buttonBindings">
          {{ buttonLabel }}
        </pt-button>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import PtButton from '~/components/global/elements/PtButton.vue';

const props: SectionProps = defineProps(useSectionProps());

const { getDisplayBlob, getBlobBindings } = useBlob();

const hasButton = false;
const buttonLabel = '';
const buttonBindings = {};

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
</script>
