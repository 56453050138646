<template>
  <div class="content-row-text-image" :class="[`is-${type}`]">
    <div class="content-row-text-image__inner">
      <div class="content-row-text-image__cols columns is-variable is-6-tablet is-8-desktop">
        <div class="content-row-text-image__col column is-6 is-image">
          <picture-basic v-if="row.image" v-bind="imageBindings" />
        </div>
        <div class="content-row-text-image__col column is-6 is-text">
          <bard :content="row.text" :as-content="true" :primary-links="!row.secondary_links" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import { getImageRatio, getPictureBindings } from '@/utils/images';
import type { ContentRowProps } from '~/types/components';
import { useContentRowProps } from '~/composables/blocks';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import Bard from '~/components/content/Bard.vue';

const props: ContentRowProps = defineProps(useContentRowProps());
const type = computed(() => getSelectValue(props.row.display_type, 'value', 'image_text'));

const imageBindings = computed(() => {
  return getPictureBindings(props.row.image, {
    ratio: getImageRatio(getSelectValue(props.row.image_ratio, 'value'), props.row.image.ratio),
    preset: 'side-large',
    zoomable: props.row.is_image_zoomable,
    displayCaption: props.row.display_caption,
    displayCredit: props.row.display_credit,
  });
});
</script>
