<template>
  <div
    ref="el"
    class="floating-link is-load"
    :class="[
      `is-${type}`,
      {
        'is-futured': link.is_featured,
      },
    ]"
  >
    <a :href="withTrailingSlash(link.link)">
      <div class="floating-link__inner">
        <h6 class="floating-link__title">
          {{ link.title }}
        </h6>
        <div class="floating-link__content">
          <picture-basic
            class="floating-link__image"
            v-bind="
              getPictureBindings(link.image, {
                contain: false,
                ratio: ratio,
                preset: 'floating-link',
              })
            "
          />
          <div class="floating-link__text">
            <div v-if="link.is_featured || type === LINK_TYPES.slider" class="floating-link__text-bard">
              <bard :content="link.excerpt" as-content />
            </div>
            <div class="floating-link__loadmore">
              <span> Read More </span>
              <pt-icon-chevron-right />
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { withTrailingSlash } from 'ufo';
import { LINK_TYPES } from '@/constants/floating-links';
import PtIconChevronRight from '~/components/global/icons/PtIconChevronRight.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import { useImages } from '~/composables/images';
import Bard from '~/components/content/Bard.vue';

const props = defineProps({
  link: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    default: LINK_TYPES.ellipse,
  },
});

const el = ref(null);
const { getPictureBindings } = useImages();

const ratio = computed(() => {
  if (props.type === LINK_TYPES.slider) {
    return 9 / 16;
  }
  if (props.link.image_ratio_select) {
    const ratio = props.link.image_ratio_select.value;
    const first = ratio.substring(0, ratio.indexOf('_'));
    const second = ratio.substring(ratio.indexOf('_') + 1);
    return Number(second) / Number(first);
  }
  return 1;
});

defineExpose({ el });
</script>
