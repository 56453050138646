<template>
  <div class="floating-links-slider">
    <floating-link-middle
      :image="background.image"
      :text="background.content"
      :link-types="linkTypes"
      :type="LINK_TYPES.slider"
      @filter-links="onFilterLinks"
    />
    <div class="floating-links-slider__content">
      <client-only>
        <swiper
          effect="cards"
          :grab-cursor="true"
          :centered-slides="false"
          :cards-effect="{
            slideShadows: false,
            rotate: true,
            perSlideRotate: -1,
            perSlideOffset: 2,
          }"
          :modules="[EffectCards]"
        >
          <swiper-slide v-for="(link, i) in links" :key="i">
            <floating-link :link="link" :type="LINK_TYPES.slider" />
          </swiper-slide>
        </swiper>
      </client-only>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCards } from 'swiper/modules';
import { LINK_TYPES } from '@/constants/floating-links';
import FloatingLink from '~/components/global/components/floating-links/FloatingLink.vue';
import FloatingLinkMiddle from '~/components/global/components/floating-links/FloatingLinkMiddle.vue';

defineProps({
  background: {
    type: Object,
    required: true,
  },
  links: {
    type: Array,
    required: true,
  },
  linkTypes: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['filter-links']);
const onFilterLinks = (filter) => emit('filter-links', filter);
</script>
