<template>
  <svg width="384" height="79" viewBox="0 0 384 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 77.0251C17.6584 52.0167 70.4602 2.00001 156.4 2.00002C263.824 2.00001 255.448 62.8451 383.629 65.4233"
      stroke="#CA3C2C"
      stroke-width="4"
      stroke-dasharray="12 12"
    />
  </svg>
</template>
