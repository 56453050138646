<template>
  <div class="feature-double">
    <div class="feature-double__inner">
      <header class="feature-double__header has-marg-bottom-s">
        <h4 v-if="item.title" class="feature-double__header-title title is-4 is-strong has-marg-bottom-xxs">
          {{ item.title }}
        </h4>
        <bard v-if="item.description" :as-content="true" :content="item.description" lead class="feature-double__header-description" />
      </header>
      <template v-if="!headerOnly">
        <div v-if="item.feature && item.feature.length" class="feature-double__content">
          <div class="feature-double__cols columns is-multiline is-variable is-5-tablet is-6-desktop">
            <div v-for="(feature, index) in item.feature" :key="`feat${index}`" class="feature-double__col column is-6">
              <div v-if="feature.icon" class="feature-double__item-icon">
                <pt-icon :size="60" :name="getIcon(feature.icon)" />
              </div>
              <h5 class="feature-double__item-title lead-label is-strong">
                {{ feature.title }}
              </h5>
              <bard :content="feature.description" :as-content="true" lead class="feature-double__item-description" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import Bard from '~/components/content/Bard.vue';
import PtIcon from '~/components/global/elements/PtIcon.vue';

defineProps({
  item: {
    type: Object,
    required: true,
  },
  headerOnly: {
    type: Boolean,
    default: false,
  },
});

const getIcon = (icon) => getSelectValue(icon, 'slug');
</script>
