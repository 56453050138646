<template>
  <div class="team-member-grid-item">
    <div class="team-member-grid-item__image-container">
      <picture-basic
        v-if="teamMember.image"
        v-bind="
          getPictureBindings(teamMember.image, {
            ratio: 1,
            contain: false,
            preset: 'team',
          })
        "
        class="team-member-grid-item__image"
      />
      <span v-else class="team-member-grid-item__image-blank" />
    </div>
    <hr class="team-member-grid-item__line" />
    <h4 v-if="teamMember.title" class="team-member-grid-item__name title is-4 is-up">
      {{ teamMember.title }}
    </h4>
    <div v-if="teamMember.subtitle" class="team-member-grid-item__role text-label is-up is-strong has-marg-top-xxs">
      {{ teamMember.subtitle }}
    </div>
    <div v-if="teamMember.short_bio" class="team-member-grid-item__bio has-marg-top-xs">
      <bard as-content :content="teamMember.short_bio" />
    </div>
    <div v-if="teamMember.email" class="team-member-grid-item__email has-marg-top-xs">
      <pt-button tag="a" :href="emailHref" size="small"> Get in touch now </pt-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatEmailHref } from '@/utils/formatters';
import PtButton from '@/components/global/elements/PtButton.vue';
import Bard from '~/components/content/Bard.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import { useImages } from '~/composables/images';

const props = defineProps({
  teamMember: {
    type: Object,
    required: true,
  },
});

const { getPictureBindings } = useImages();

const emailHref = computed(() => formatEmailHref(props.teamMember.email));
</script>
