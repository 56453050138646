<template>
  <div class="block-buttons">
    <div class="block-buttons__inner container">
      <pt-section-header :title="section.title" :title-tag="titleTag" :description="section.description" class="has-marg-bottom-l" />
      <pt-buttons v-if="buttons.length" centered tiny-full>
        <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
          {{ button.label }}
        </pt-button>
      </pt-buttons>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import PtButton from '~/components/global/elements/PtButton.vue';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import { useBlob } from '~/composables/blob';
import { getSectionButtons, getSelectValue } from '~/utils/blocks';
import { useButtons } from '~/composables/buttons';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();
const { getButtonBindings } = useButtons();

const buttons = computed(() => getSectionButtons(props.section.buttons));
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
</script>
