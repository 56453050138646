<template>
  <div class="block-separator" :class="`is-${width}`">
    <div class="block-separator__inner container">
      <hr class="block-separator__line" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import { useSectionProps } from '~/composables/blocks';
import type { SectionProps } from '~/types/components';

const props: SectionProps = defineProps(useSectionProps());

const width = computed(() => getSelectValue(props.section.width, 'value', 'full'));
</script>
