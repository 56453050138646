import { getSelectValue } from '~/utils/blocks';

export const useExtendedButtons = () => {
  const getExtendedButtonsPerRow = (perRow: string) => {
    const value = getSelectValue(perRow);

    switch (value) {
      case 'one':
        return 1;
      case 'two':
        return 2;
      case 'three':
        return 3;
      default:
        return 4;
    }
  };

  const getExtendedButtonsWidth = (width: string) => {
    const value = getSelectValue(width);
    return value || 'full';
  };

  return {
    getExtendedButtonsPerRow,
    getExtendedButtonsWidth,
  };
};
