<template>
  <div class="block-partner-logos">
    <div class="container">
      <pt-section-header :title="section.title" :title-tag="titleTag" :description="section.description" />
    </div>
    <pt-slider
      name="block-partner-logos"
      :items="list"
      :options="{ watchOverflow: true, freeMode: true, loop: true }"
      class="block-partner-logos__slider has-marg-top-xl"
    >
      <template #default="item">
        <picture-basic v-bind="getPictureBindings(item.item, { contain: true })" />
      </template>
    </pt-slider>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useImages } from '~/composables/images';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import PtSlider from '~/components/global/elements/PtSlider.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';

const MIN_ITEMS_QTY = 10;
const props: SectionProps = defineProps(useSectionProps());
const { getPictureBindings } = useImages();
const list = computed(() => {
  const logos = props.section.logos;
  let res = [];

  if (!logos || !Array.isArray(logos) || logos.length === 0) return res;

  while (res.length < MIN_ITEMS_QTY) {
    res = res.concat(logos);
  }

  return res;
});
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
</script>
