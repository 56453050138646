<template>
  <div class="block-image-text" :class="rootClasses">
    <div class="block-image-text__inner container" :class="innerClasses">
      <div class="block-image-text__cols columns is-variable is-0-mobile is-6-tablet is-8-desktop">
        <div class="block-image-text__col is-image column" :class="columnClasses">
          <picture-basic v-if="section.image" v-bind="imageBindings" class="block-image-text__fig" />
        </div>
        <div class="block-image-text__col is-content column" :class="columnClasses">
          <div class="block-image-text__content-inner" :class="contentClasses">
            <component :is="titleTag" class="block-image-text__title title is-2">
              {{ section.title }}
            </component>
            <bard class="block-image-text__content lead-label has-marg-top-xs" :content="section.content" :as-content="true" />
            <div v-if="buttons.length" class="block-image-text__buttons has-marg-top-m">
              <pt-buttons>
                <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
                  {{ button.label || 'Learn More' }}
                </pt-button>
              </pt-buttons>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section) && !isBoxedVariant()" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { useSectionProps } from '~/composables/blocks';
import { useImages } from '~/composables/images';
import { getSectionButtons, getSelectValue } from '~/utils/blocks';
import { getImageRatio } from '~/utils/images';
import { useButtons } from '~/composables/buttons';
import { useBlob } from '~/composables/blob';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import Bard from '~/components/content/Bard.vue';
import type { SectionProps } from '~/types/components';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';

const props: SectionProps = defineProps(useSectionProps());
const { getPictureBindings } = useImages();
const { getButtonBindings } = useButtons();
const { getDisplayBlob, getBlobBindings } = useBlob();

const rootClasses = computed(() => {
  const classes = [];
  const display = getSelectValue(props.section.display_type);
  const variant = getSelectValue(props.section.variant);
  const imagePositionMobile = getSelectValue(props.section.image_position_mobile);
  const opacity = props.section.opacity;

  if (display) {
    classes.push(`is-display-${display}`);
  }
  if (variant) {
    classes.push(`is-variant-${variant}`);
  }
  if (imagePositionMobile) {
    classes.push(`is-image-${imagePositionMobile}`);
  }
  if (opacity) {
    classes.push('has-opacity');
  }
  return classes;
});

const innerClasses = computed(() => {
  const classes = [];
  const width = getSelectValue(props.section.width);
  if (width) {
    classes.push(`is-${width}`);
  }
  return classes;
});

const contentClasses = computed(() => {
  const classes = [];
  const bg = getSelectValue(props.section.background_colour);
  if (bg) {
    classes.push(`is-${bg}`);
  }
  return classes;
});

const columnClasses = computed(() => {
  const classes = [];
  if (isBoxedVariant() && !props.section.image) {
    classes.push('is-12');
  } else {
    classes.push('is-6');
  }

  return classes;
});

const buttons = computed(() => getSectionButtons(props.section.buttons));

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));

const imageBindings = computed(() => {
  if (isBoxedVariant()) {
    return getPictureBindings(props.section.image, {
      ratio: null,
      contain: false,
      zoomable: props.section.is_image_zoomable,
      preset: 'full',
    });
  }

  return getPictureBindings(props.section.image, {
    ratio: getImageRatio(getSelectValue(props.section.image_ratio, 'value')),
    zoomable: props.section.is_image_zoomable,
    displayCaption: props.section.display_caption,
    displayCredit: props.section.display_credit,
  });
});

const isBoxedVariant = () => {
  return getSelectValue(props.section.variant) === 'boxed';
};
</script>
