<template>
  <div class="block-sitemap">
    <div class="block-sitemap__inner container is-narrower">
      <Sitemap :items="sitemap" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from '#imports';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import Sitemap from '~/components/global/components/sitemap/Sitemap.vue';

const props: SectionProps = defineProps(useSectionProps());

const sitemap = computed(() => props.section.sitemap || []);
</script>
