<template>
  <div class="buttons-ext" :class="rootClasses">
    <template v-if="$slots.default">
      <slot />
    </template>
    <template v-else>
      <div class="buttons-ext__cols columns is-multiline" :class="columnsClass">
        <div v-for="(button, index) in buttons" :key="`ext-button-${index}`" class="column buttons-ext__col" :class="columnClasses">
          <pt-extended-button v-bind="getExtendedButtonBindings(button)">
            {{ button.label }}
          </pt-extended-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import PtExtendedButton from '~/components/global/elements/PtExtendedButton.vue';
import { type ButtonBindings, useButtons } from '~/composables/buttons';

const props = defineProps({
  width: {
    type: String,
    default: 'full',
    validator: (value: string) => ['', 'full', 'narrow'].includes(value),
  },
  perRow: {
    type: Number,
    default: 4,
    validator: (value: number) => value <= 4 && value > 0,
  },
  buttons: {
    type: Array as () => ButtonBindings[],
    required: true,
  },
  columnsClass: {
    type: [Array, Object, String],
    default: '',
  },
  columnClass: {
    type: [Array, Object, String],
    default: '',
  },
});

const { getButtonBindings } = useButtons();

const getExtendedButtonBindings = (button: ButtonBindings) => {
  return {
    description: button.description || '',
    textSize: getSelectValue(button.text_size),
    displayLine: button.display_line || false,
    hasArrow: button.has_arrow || false,
    hasIcon: button.has_icon || false,
    hasBorder: button.has_border || false,
    icon: getSelectValue(button.icon, 'slug'),
    label: button.label || null,
    ...getButtonBindings(button),
  };
};

const rootClasses = computed(() => {
  const classes = [];

  if (props.width) {
    classes.push(`is-${props.width}`);
  }
  return classes;
});

const columnClasses = computed(() => {
  const classes = [];

  const columnWidth = 12 / props.perRow;
  classes.push(`is-${columnWidth}`);

  switch (props.perRow) {
    case 4:
      classes.push('is-6-tablet');
      classes.push('is-3-desktop');
      break;
    default:
      break;
  }

  if (props.columnClass) {
    classes.push(props.columnClass);
  }

  return classes;
});
</script>
