<template>
  <div class="buttons-menu">
    <template v-if="$slots.default">
      <slot />
    </template>
    <template v-else>
      <pt-menu-button
        v-for="(button, index) in buttons"
        :key="`ext-button-${index}`"
        v-bind="getExtendedButtonBindings(button)"
        class="buttons-menu__item"
      >
        {{ button.label }}
      </pt-menu-button>
    </template>
  </div>
</template>

<script setup lang="ts">
import { isLinkAction, isLinkExternal } from '@/utils/links';
import { slashed, urlify } from '@/utils/url';
import PtMenuButton from '~/components/global/elements/PtMenuButton.vue';

defineProps({
  buttons: {
    type: Array,
    required: true,
  },
});

const getExtendedButtonBindings = (button) => {
  const external = isLinkExternal(button.link);
  const action = isLinkAction(button.link);
  const bindings = {
    label: button.label || null,
  };

  if (external) {
    bindings.tag = 'a';
    bindings.href = urlify(button.link);
    bindings.target = '_blank';
  } else if (action) {
    bindings.tag = 'pt-link-action';
    bindings.action = button.link;
    bindings.href = '#';
  } else {
    bindings.tag = 'app-link';
    bindings.to = slashed(button.link);
  }

  return bindings;
};
</script>
