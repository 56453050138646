import type { LooseObject } from '~/types/generic';

export function useVideos() {
  const getVideoBindings = (video: any, additional: LooseObject = {}): LooseObject => {
    return {
      contain: true,
      url: video.url,
      ...additional,
    };
  };

  return {
    getVideoBindings,
  };
}
