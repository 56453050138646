<template>
  <div class="block-hero" :class="{ 'has-ext-buttons': extendedButtons.length }">
    <div class="block-hero__inner container">
      <div class="block-hero__cols">
        <div class="block-hero__col is-image">
          <picture-basic v-if="section.image" class="block-hero__fig" v-bind="imageBindings" />
        </div>
        <div class="block-hero__col is-content">
          <div class="block-hero__content">
            <component :is="titleTag" v-if="section.title" class="block-hero__title title is-1">
              {{ section.title }}
            </component>
            <bard
              v-if="section.description"
              :as-content="true"
              :content="section.description"
              class="block-hero__description has-marg-top-m is-lead"
            />
          </div>
          <div v-if="buttons.length" class="block-hero__buttons has-marg-top-m">
            <pt-buttons>
              <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
                {{ button.label }}
              </pt-button>
            </pt-buttons>
          </div>
        </div>
      </div>
      <pt-extended-buttons
        v-if="extendedButtons.length"
        class="block-hero__extended"
        :width="getExtendedButtonsWidth(section.extended_buttons_width)"
        :per-row="getExtendedButtonsPerRow(section.extended_buttons_per_row)"
        :buttons="extendedButtons"
      />
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useSectionProps } from '~/composables/blocks';
import { ensureArrayValue, getSectionButtons, getSelectValue } from '~/utils/blocks';
import { useImages } from '~/composables/images';
import type { SectionProps } from '~/types/components';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import Bard from '~/components/content/Bard.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import PtExtendedButtons from '~/components/global/elements/PtExtendedButtons.vue';
import { useBlob } from '~/composables/blob';
import { useExtendedButtons } from '~/composables/extended-buttons';
import { useButtons } from '~/composables/buttons';

const props: SectionProps = defineProps(useSectionProps());
const { getPictureBindings } = useImages();
const { getDisplayBlob, getBlobBindings } = useBlob();
const { getExtendedButtonsWidth, getExtendedButtonsPerRow } = useExtendedButtons();
const { getButtonBindings } = useButtons();

const imageBindings = computed(() => {
  return getPictureBindings(props.section.image, {
    ratio: getImageRatio(getSelectValue(props.section.image_ratio, 'value')),
    loading: '',
  });
});

const buttons = computed(() => getSectionButtons(props.section.buttons));
const extendedButtons = computed(() => ensureArrayValue(props.section.extended_buttons));
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h1'));
</script>
