<template>
  <div class="block-form">
    <div class="block-form__inner container is-narrower">
      <marketo-form v-bind="formBindings" @success="onSuccess" />
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { useGtm } from '@gtm-support/vue-gtm';
import { useRouter } from 'vue-router';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import MarketoForm from '~/components/global/forms/MarketoForm.vue';
import { isLinkExternal, isLinkInternal } from '@/utils/links';
import { slashed } from '@/utils/url';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import type { LooseObject } from '~/types/generic';

const gtm = useGtm();
const router = useRouter();
const { section }: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();

const formId = computed(() => section.marketo_form_id || null);
const uniqId = computed(() => section.marketo_uniq_id || null);
const apiUrl = computed(() => section.marketo_api_url || null);
const formBindings = computed(() => {
  const bindings: LooseObject = {
    formId: formId.value,
    uniqId: uniqId.value,
  };

  if (apiUrl.value) {
    bindings.apiUrl = apiUrl.value;
  }
  return bindings;
});

const fireEvent = () => {
  if (gtm && section.gtm_event_name) {
    gtm.push({ event: section.gtm_event_name });
  }
};

const maybeRedirect = () => {
  if (!section.redirect || !section.redirect_url) return;

  if (isLinkInternal(section.redirect_url)) {
    router.push({ path: slashed(section.redirect_url) });
    return;
  }

  if (isLinkExternal(section.redirect_url)) {
    window.location.replace(section.redirect_url);
  }
};

const onSuccess = () => {
  fireEvent();
  maybeRedirect();
};
</script>
