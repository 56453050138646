<template>
  <div class="block-floating-links">
    <div class="block-floating-links__inner container">
      <floating-links-ellipse
        v-if="linksTypeIs === LINK_TYPES.ellipse"
        :background="{
          image: section.background_image,
          content: section.background_content,
        }"
        :links="links"
        :link-types="linkTypes"
        :filter="filter"
        @filter-links="filter = $event"
      />

      <floating-links-slider
        v-else-if="linksTypeIs === LINK_TYPES.slider"
        :background="{
          image: section.background_image,
          content: section.background_content,
        }"
        :links="links"
        :link-types="linkTypes"
        @filter-links="getLinks"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWindowSize, watchDebounced } from '@vueuse/core';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { BREAKPOINTS } from '~/constants/layout';
import { LINK_TYPES } from '~/constants/floating-links';
import FloatingLinksEllipse from '~/components/global/components/floating-links/FloatingLinksEllipse.vue';
import FloatingLinksSlider from '~/components/global/components/floating-links/FloatingLinksSlider.vue';

const props: SectionProps = defineProps(useSectionProps());
const links = ref([]);
const filter = ref(null);
const linksTypeIs = ref(null);
const linkTypes = computed(() => props.section?.link_groups?.map((link) => link.group_title) ?? []);
const { width: windowWidth } = useWindowSize();

const getLinks = (filter = null) => {
  const result = [];

  props.section?.link_groups?.forEach((group) => {
    if (filter === group.group_title) {
      result.push(...group.links);
    } else if (!filter) {
      group.links?.forEach((link) => {
        result.push({ ...link, type: group.group_title });
      });
    }
  });

  links.value = result;
};

const linksType = () => {
  if (windowWidth.value >= BREAKPOINTS.desktop) {
    linksTypeIs.value = LINK_TYPES.ellipse;
  } else {
    linksTypeIs.value = LINK_TYPES.slider;
  }
};

onMounted(() => {
  getLinks();
  linksType();
});

watchDebounced(windowWidth, linksType, {
  debounce: 100,
});
</script>
