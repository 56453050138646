<template>
  <div class="floating-links-filter" :class="`is-${type}`">
    <div class="floating-links-filter__buttons">
      <button
        v-for="linkType in linkTypes"
        :key="`button-${linkType}`"
        type="button"
        class="floating-links-filter__button button is-small is-lightblue"
        :class="{
          'is-active': filter === linkType,
          'is-not-hovered': isHovered(linkType),
        }"
        @click="filterLinks(linkType)"
        @mouseover="hoveredFilterLinks(linkType)"
        @mouseout="hoveredFilterLinks(null)"
      >
        {{ linkType }}
      </button>
    </div>
    <div
      class="floating-links-filter__reset"
      :class="{
        'is-show': !!filter,
      }"
    >
      <button type="button" @click="filterLinks(null)">Reset Filter</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LINK_TYPES } from '@/constants/floating-links';

const emit = defineEmits(['filter-links', 'hover-links']);

defineProps({
  linkTypes: {
    type: Array,
    required: true,
  },
  type: {
    type: String,
    default: LINK_TYPES.ellipse,
  },
});

const filter = ref(null);
const hoveredFilter = ref(null);

const filterLinks = (f) => {
  if (filter.value === f) {
    filter.value = null;
  } else {
    filter.value = f;
  }
  emit('filter-links', filter.value);
};

const hoveredFilterLinks = (f) => {
  hoveredFilter.value = filter.value ? null : f;
  emit('hover-links', hoveredFilter.value);
};

const isHovered = (type) => (hoveredFilter.value !== null) & (hoveredFilter.value !== type) && !filter.value;
</script>
