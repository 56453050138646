<template>
  <div class="contact-info">
    <div class="contact-info__section has-marg-bottom-l">
      <component :is="titleTag" v-if="title" class="contact-info__title is-main title is-2 has-marg-bottom-s">
        {{ title }}
      </component>
      <ul class="contact-info__list lead-label">
        <li class="contact-info__list-item has-marg-bottom-xs">
          <pt-icon class="contact-info__list-icon" name="phone-alt" :size="24" />
          <a :href="contactPhoneHref" class="contact-info__list-link link-primary is-3">
            <span class="link-primary__label">
              {{ contactPhone }}
            </span>
          </a>
          <span v-if="contactPhoneAvailability" class="contact-info__list-suffix">{{ contactPhoneAvailability }}</span>
        </li>
        <li class="contact-info__list-item has-marg-bottom-xs">
          <pt-icon class="contact-info__list-icon" name="mail" :size="24" />
          <a :href="supportEmailHref" class="contact-info__list-link link-primary is-3">
            <span class="link-primary__label">
              {{ supportEmail }}
            </span>
          </a>
        </li>
        <li class="contact-info__list-item has-marg-bottom-xs">
          <pt-icon class="contact-info__list-icon" name="airplane" :size="24" />
          <pt-link-action action="contact" class="contact-info__list-link link-primary is-3">
            <span class="link-primary__label"> Contact us form </span>
          </pt-link-action>
        </li>
        <li class="contact-info__list-item has-marg-bottom-xs">
          <pt-icon class="contact-info__list-icon" name="team-meeting" :size="24" />
          <app-link to="/locations/" class="contact-info__list-link link-primary is-3">
            <span class="link-primary__label"> Book an appointment </span>
          </app-link>
        </li>
        <li class="contact-info__list-item has-marg-bottom-xs">
          <pt-icon class="contact-info__list-icon" name="feedback" :size="24" />
          <app-link to="/contact-us/feedback/" class="contact-info__list-link link-primary is-3">
            <span class="link-primary__label"> Complaints & Feedback </span>
          </app-link>
        </li>
      </ul>
    </div>
    <div v-if="pobox" class="contact-info__section has-marg-bottom-l">
      <component :is="subTitleTag" v-if="poboxTitle" class="contact-info__title is-sub title is-3 has-marg-bottom-xxs">
        {{ poboxTitle }}
      </component>
      <bard :content="pobox" as-content />
    </div>

    <div v-if="liveChatWorkingHours" class="contact-info__section has-marg-bottom-l">
      <component :is="subTitleTag" v-if="poboxTitle" class="contact-info__title is-sub title is-3 has-marg-bottom-xxs">
        {{ liveChatTitle }}
      </component>
      <ul class="contact-info__list lead-label">
        <li class="contact-info__list-item">
          <bard :content="liveChatWorkingHours" as-content />
        </li>
      </ul>
    </div>

    <div class="contact-info__section has-marg-bottom-l">
      <div v-if="overseasTitle && overseasPhone" class="has-marg-bottom-m">
        <component :is="subTitleTag" class="contact-info__title is-sub title is-3 has-marg-bottom-xs">
          {{ overseasTitle }}
        </component>
        <ul class="contact-info__list lead-label">
          <li class="contact-info__list-item">
            <pt-icon class="contact-info__list-icon" name="style-two-pin-world" :size="24" />
            <a :href="overseasPhoneHref" class="contact-info__list-link link-primary is-3">
              <span class="link-primary__label">
                {{ overseasPhone }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="contact-info__section has-marg-bottom-l">
      <div v-if="mediaTitle && (mediaEmail || mediaPhone)" class="has-marg-bottom-m">
        <component
          :is="subTitleTag"
          class="contact-info__title is-sub title is-3"
          :class="{ 'has-marg-bottom-xs': !mediaPerson, 'has-marg-bottom-xxs': mediaPerson }"
        >
          {{ mediaTitle }}
        </component>
        <div v-if="mediaPerson" class="contact-info__subtitle title is-4 has-marg-bottom-xs">
          {{ mediaPerson }}
        </div>

        <ul class="contact-info__list lead-label">
          <li v-if="mediaEmail" class="contact-info__list-item has-marg-bottom-xs">
            <pt-icon class="contact-info__list-icon" name="camera" :size="24" />
            <a :href="mediaEmailHref" class="contact-info__list-link link-primary is-3">
              <span class="link-primary__label">
                {{ mediaEmail }}
              </span>
            </a>
          </li>
          <li v-if="mediaPhone" class="contact-info__list-item">
            <pt-icon class="contact-info__list-icon" name="phone-alt" :size="24" />
            <a :href="mediaPhoneHref" class="contact-info__list-link link-primary is-3">
              <span class="link-primary__label">
                {{ mediaPhone }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="marketingTitle && marketingEmail" class="contact-info__section">
      <component :is="subTitleTag" class="contact-info__title is-sub title is-3 has-marg-bottom-xs">
        {{ marketingTitle }}
      </component>
      <ul class="contact-info__list lead-label">
        <li v-if="marketingEmail" class="contact-info__list-item">
          <pt-icon class="contact-info__list-icon" name="camera" :size="24" />
          <a :href="marketingEmailHref" class="contact-info__list-link link-primary is-3">
            <span class="link-primary__label">
              {{ marketingEmail }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatEmailHref, formatPhoneHref } from '@/utils/formatters';
import { useGlobalsStore } from '~/stores/globals';
import PtIcon from '~/components/global/elements/PtIcon.vue';
import PtLinkAction from '~/components/global/elements/PtLinkAction.vue';
import Bard from '~/components/content/Bard.vue';
import AppLink from '~/components/AppLink';

defineProps({
  title: {
    type: String,
    default: null,
  },
  titleTag: {
    type: String,
    default: 'h2',
  },
  subTitleTag: {
    type: String,
    default: 'h3',
  },
  marketingTitle: {
    type: String,
    default: null,
  },
  mediaTitle: {
    type: String,
    default: null,
  },
  overseasTitle: {
    type: String,
    default: null,
  },
  poboxTitle: {
    type: String,
    default: null,
  },
  liveChatTitle: {
    type: String,
    default: null,
  },
});

const globalStore = useGlobalsStore();

const contact = computed(() => globalStore.contact);

const contactPhone = computed(() => contact.value.phone || null);
const contactPhoneHref = computed(() => formatPhoneHref(contactPhone.value));
const contactPhoneAvailability = computed(() => contact.value.phone_availability || null);

const supportEmail = computed(() => contact.value.support_email || null);
const supportEmailHref = computed(() => formatEmailHref(supportEmail.value));

const overseasPhone = computed(() => contact.value.overseas_phone || null);
const overseasPhoneHref = computed(() => formatPhoneHref(overseasPhone.value));

const marketingEmail = computed(() => contact.value.marketing_enquiries_email || null);
const marketingEmailHref = computed(() => formatEmailHref(marketingEmail.value));

const mediaEmail = computed(() => contact.value.media_enquiries_email || null);
const mediaEmailHref = computed(() => formatEmailHref(mediaEmail.value));

const mediaPhone = computed(() => contact.value.media_enquiries_phone || null);
const mediaPhoneHref = computed(() => formatPhoneHref(mediaPhone.value));

const mediaPerson = computed(() => contact.value.media_enquiries_contact_person || null);

const pobox = computed(() => contact.value.po_box_address || null);

const liveChatWorkingHours = computed(() => contact.value.live_chat_working_hours || null);
</script>
