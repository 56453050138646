<template>
  <component :is="componentName" :id="`section${sectionIndex}`" :section="section" :section-index="sectionIndex" :class="rootClasses" />
</template>

<script lang="ts">
import { computed, defineComponent } from '#imports';
import { getSectionProps, getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '@/types/components';
import BlockSitemap from '~/components/content/blocks/BlockSitemap.vue';
import BlockHero from '~/components/content/blocks/BlockHero.vue';
import BlockImageText from '~/components/content/blocks/BlockImageText.vue';
import BlockSeparator from '~/components/content/blocks/BlockSeparator.vue';
import BlockExtendedButtons from '~/components/content/blocks/BlockExtendedButtons.vue';
import BlockTitleText from '~/components/content/blocks/BlockTitleText.vue';
import BlockImagesText from '~/components/content/blocks/BlockImagesText.vue';
import BlockHeroCta from '~/components/content/blocks/BlockHeroCta.vue';
import BlockAnchor from '~/components/content/blocks/BlockAnchor.vue';
import BlockContent from '~/components/content/blocks/BlockContent.vue';
import BlockTestimonials from '~/components/content/blocks/BlockTestimonials.vue';
import BlockEmbed from '~/components/content/blocks/BlockEmbed.vue';
import BlockEmbedText from '~/components/content/blocks/BlockEmbedText.vue';
import BlockLocations from '~/components/content/blocks/BlockLocations.vue';
import BlockPageAnchors from '~/components/content/blocks/BlockPageAnchors.vue';
import BlockTextSidebar from '~/components/content/blocks/BlockTextSidebar.vue';
import BlockDoubleFeatures from '~/components/content/blocks/BlockDoubleFeatures.vue';
import BlockProcess from '~/components/content/blocks/BlockProcess.vue';
import BlockContact from '~/components/content/blocks/BlockContact.vue';
import BlockButtons from '~/components/content/blocks/BlockButtons.vue';
import BlockPricing from '~/components/content/blocks/BlockPricing.vue';
import BlockAccordion from '~/components/content/blocks/BlockAccordion.vue';
import BlockBooking from '~/components/content/blocks/BlockBooking.vue';
import BlockFloatingLinks from '~/components/content/blocks/BlockFloatingLinks.vue';
import BlockHistoryTimeline from '~/components/content/blocks/BlockHistoryTimeline.vue';
import BlockTriageTool from '~/components/content/blocks/BlockTriageTool.vue';
import BlockCareersFeatures from '~/components/content/blocks/BlockCareersFeatures.vue';
import BlockCareersGallery from '~/components/content/blocks/BlockCareersGallery.vue';
import BlockCta from '~/components/content/blocks/BlockCta.vue';
import BlockEpa from '~/components/content/blocks/BlockEpa.vue';
import BlockFeaturesList from '~/components/content/blocks/BlockFeaturesList.vue';
import BlockHeroCoverImage from '~/components/content/blocks/BlockHeroCoverImage.vue';
import BlockHeroSimple from '~/components/content/blocks/BlockHeroSimple.vue';
import BlockImage from '~/components/content/blocks/BlockImage.vue';
import BlockMetrics from '~/components/content/blocks/BlockMetrics.vue';
import BlockParagraph from '~/components/content/blocks/BlockParagraph.vue';
import BlockPartnerLogos from '~/components/content/blocks/BlockPartnerLogos.vue';
import BlockTeamMembers from '~/components/content/blocks/BlockTeamMembers.vue';
import BlockTeamMembersGrid from '~/components/content/blocks/BlockTeamMembersGrid.vue';
import BlockTeamMembersList from '~/components/content/blocks/BlockTeamMembersList.vue';
import BlockFeaturedResources from '~/components/content/blocks/BlockFeaturedResources.vue';
import BlockFeaturedVideos from '~/components/content/blocks/BlockFeaturedVideos.vue';
import BlockFeaturedFaqs from '~/components/content/blocks/BlockFeaturedFaqs.vue';
import BlockFeaturedFiles from '~/components/content/blocks/BlockFeaturedFiles.vue';
import BlockMarketoForm from '~/components/content/blocks/BlockMarketoForm.vue';

export default defineComponent({
  components: {
    BlockSitemap,
    BlockHero,
    BlockImageText,
    BlockExtendedButtons,
    BlockSeparator,
    BlockTitleText,
    BlockImagesText,
    BlockHeroCta,
    BlockAnchor,
    BlockContent,
    BlockTestimonials,
    BlockEmbed,
    BlockEmbedText,
    BlockLocations,
    BlockPageAnchors,
    BlockTextSidebar,
    BlockDoubleFeatures,
    BlockProcess,
    BlockContact,
    BlockButtons,
    BlockPricing,
    BlockAccordion,
    BlockBooking,
    BlockFloatingLinks,
    BlockHistoryTimeline,
    BlockTriageTool,
    BlockCareersFeatures,
    BlockCareersGallery,
    BlockCta,
    BlockEpa,
    BlockFeaturesList,
    BlockHeroCoverImage,
    BlockHeroSimple,
    BlockImage,
    BlockMetrics,
    BlockParagraph,
    BlockPartnerLogos,
    BlockTeamMembers,
    BlockTeamMembersGrid,
    BlockTeamMembersList,
    BlockFeaturedResources,
    BlockFeaturedVideos,
    BlockFeaturedFaqs,
    BlockFeaturedFiles,
    BlockMarketoForm,
  },

  props: getSectionProps(),

  setup(props: SectionProps) {
    const section = computed(() => props.section);
    const sectionComponent = computed(() => section.value.type.split('_').join('-'));
    const componentName = computed(() => `${sectionComponent.value}`);
    const rootClasses = computed(() => {
      const classes = ['section-block', `is-${sectionComponent.value}`];

      const spacing = [
        {
          key: 'margin_top',
          cl: 'marg-top',
        },
        {
          key: 'margin_bottom',
          cl: 'marg-bottom',
        },
        {
          key: 'padding_top',
          cl: 'pad-top',
        },
        {
          key: 'padding_bottom',
          cl: 'pad-bottom',
        },
      ];

      spacing.forEach((space) => {
        if (!section.value[space.key]) {
          return;
        }
        const sp = getSelectValue(section.value[space.key]);
        if (sp) {
          classes.push(`has-${space.cl}-${sp}`);
        }
      });

      if (section.value.blob_enabled) {
        classes.push('has-blob');
      }

      if (section.value.has_legal_bullet_point_style) {
        classes.push('has-legal-bullet-point-style');
      }

      if (section.value.hide_on_mobile) {
        classes.push('is-hide-on-mobile');
      }

      return classes;
    });

    return {
      rootClasses,
      componentName,
    };
  },
});
</script>
