<template>
  <svg width="891" height="156" viewBox="0 0 891 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 1.5C92.5 87.5 169.5 156.5 318.5 153.5C485.5 150.138 457.5 30 586 24C714.5 18 791 118 890 111"
      stroke="#CA3C2C"
      stroke-width="4"
      stroke-dasharray="12 12"
    />
  </svg>
</template>
