<template>
  <div class="block-embed-text" :class="rootClasses">
    <div class="block-embed-text__inner container" :class="innerClasses">
      <div class="block-embed-text__cols columns is-variable is-0-mobile is-6-tablet is-8-desktop">
        <div class="block-embed-text__col is-embed column is-6">
          <div class="block-embed-text__embed">
            <embed-block v-bind="embedBindings" />
          </div>
        </div>
        <div class="block-embed-text__col is-content column is-6">
          <component :is="titleTag" v-if="section.title" class="block-embed-text__title title is-2 has-marg-bottom-xs">
            {{ section.title }}
          </component>
          <bard class="block-embed-text__content" :content="section.content" :as-content="true" />
          <div v-if="buttons.length" class="block-image-text__buttons has-marg-top-m">
            <pt-buttons>
              <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
                {{ button.label || 'Learn More' }}
              </pt-button>
            </pt-buttons>
          </div>
        </div>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { getSectionButtons, getSelectValue } from '@/utils/blocks';
import EmbedBlock from '@/components/global/components/embed/EmbedBlock';
import Bard from '~/components/content/Bard.vue';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import { useButtons } from '~/composables/buttons';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();
const { getButtonBindings } = useButtons();

const rootClasses = computed(() => {
  const classes = [];
  const display = getSelectValue(props.section.display_type);
  if (display) {
    classes.push(`is-display-${display}`);
  }
  return classes;
});

const innerClasses = computed(() => {
  const classes = [];
  const width = getSelectValue(props.section.width);
  if (width) {
    classes.push(`is-${width}`);
  }
  return classes;
});

const buttons = computed(() => getSectionButtons(props.section.buttons));
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));

const embedBindings = computed(() => {
  const type = getSelectValue(props.section.embed_type);

  return {
    type,
    responsive: props.section.embed_responsive,
    ratio: getSelectValue(props.section.embed_ratio),
    code: type === 'video' ? props.section.embed_url : props.section.embed_code,
  };
});
</script>
