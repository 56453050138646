<template>
  <div class="timeline-slide">
    <div class="timeline-slide__header">
      <h4 class="timeline-slide__header-title">
        {{ item.year }}
      </h4>
    </div>
    <div class="timeline-slide__events">
      <div v-for="event in item.events" :key="event.id" class="timeline-slide__event" :style="`order: ${event.order};`" @click="handleClick(event)">
        <h5 class="timeline-slide__event-title">
          {{ event.title }}
        </h5>
        <div class="timeline-slide__event-image">
          <picture-basic
            v-bind="
              getPictureBindings(event.featured_image, {
                contain: false,
                ratio: 3 / 5,
              })
            "
          />
          <div class="timeline-slide__event-image-overlay">Read More</div>
        </div>
        <bard class="timeline-slide__event-desc" :content="event.excerpt" as-content />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Bard from '~/components/content/Bard.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import { TIME_LINE_POPUP } from '~/constants/popups';
import { useImages } from '~/composables/images';
import { usePopupsStore } from '~/stores/popups';
import { useTimelineStore } from '~/stores/timeline';

const { getPictureBindings } = useImages();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const popupsStore = usePopupsStore();
const timelineStore = useTimelineStore();
const handleClick = (event) => {
  timelineStore.setCurrentEvent(event);
  popupsStore.openPopup(TIME_LINE_POPUP);
};
</script>
