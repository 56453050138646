<template>
  <div v-if="embedUrl" class="content-row-image">
    <div class="content-row-image__inner">
      <div class="embed" :class="embedClass" :style="embedStyle">
        <iframe
          loading="lazy"
          sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
          class="embed__if"
          allowfullscreen
          :src="embedUrl"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useContentRowProps } from '~/composables/blocks';
import type { ContentRowProps } from '~/types/components';

const props: ContentRowProps = defineProps(useContentRowProps());

const videos = [
  {
    reg: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/i,
    url: 'https://www.youtube.com/embed/$5',
    params: {
      'picture-in-picture': 1,
      accelerometer: 1,
      gyroscope: 1,
    },
  },
  {
    reg: /^.*vimeo.com\/(\d+)($|\/|\b)/i,
    url: 'https://player.vimeo.com/video/$1',
    params: {
      title: 0,
      byline: 0,
      portrait: 0,
    },
  },
];

const ratio = computed(() => props.row.ratio || '16x9');

const embedStyle = computed(() => {
  const r = ratio.value.split('x').map((r) => Number(r));
  if (r.length < 2) {
    return null;
  }

  const paddingTop = (r[1] / r[0]) * 100;

  return {
    'padding-top': `${paddingTop}%`,
  };
});

const embedClass = computed(() => {
  return embedStyle.value ? 'is-r' : `is-${props.ratio}`;
});

const embedUrl = computed(() => {
  if (!props.row.video) {
    return null;
  }

  for (let i = 0; i < videos.length; i++) {
    const v = videos[i];
    const m = v.reg.exec(props.row.video);
    if (m) {
      const params = {
        ...v.params,
      };
      const query = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
      const and = v.url.includes('?') ? '&' : '?';
      return `${props.row.video.replace(v.reg, v.url)}${and}${query}`;
    }
  }

  return null;
});
</script>
