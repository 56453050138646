<template>
  <div class="block-anchor">
    <a :id="anchor" :name="anchor" class="block-anchor__anchor" />
  </div>
</template>

<script setup lang="ts">
import { getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';

const props: SectionProps = defineProps(useSectionProps());
const anchor = computed(() => getSelectValue(props.section.anchor));
</script>
