<template>
  <div class="block-featured-faqs container">
    <featured-block-header :title="section.title" :intro="section.intro" />
    <client-only>
      <ais-instant-search
        :index-name="faqsIndex"
        :routing="undefined"
        :search-client="searchClient"
        :insights="true"
        :future="{
          preserveSharedStateOnUnmount: true,
        }"
      >
        <ais-configure :analytics="true" :hits-per-page.camel="hitsPerPage" :filters="predefinedFilters" :distinct="true" />

        <div class="block-featured__inner" :class="{ 'has-menu': hasMenu }">
          <categories-menu v-show="hasMenu">
            <ais-hierarchical-menu :attributes="['categories.lvl0', 'categories.lvl1']" :transform-items="transformFilters" />
          </categories-menu>
          <div>
            <ais-state-results>
              <ais-hits :transform-items="transformItems">
                <template #default="{ items }">
                  <featured-faqs-list :items="items" />
                </template>
              </ais-hits>
            </ais-state-results>
            <ais-pagination class="block-featured-faqs__pagination" :padding="1">
              <template #default="{ currentRefinement, nbPages, nbHits, refine, createURL, isFirstPage, isLastPage }">
                <featured-pagination
                  :show-label="false"
                  :create-url="createURL"
                  :refine="refine"
                  :nb-pages="nbPages"
                  :nb-hits="nbHits"
                  :current-refinement="currentRefinement"
                  :is-first-page="isFirstPage"
                  :is-last-page="isLastPage"
                />
              </template>
            </ais-pagination>
          </div>
        </div>
      </ais-instant-search>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useNuxtApp, useRuntimeConfig } from '#app';
import orderBy from 'lodash/orderBy';
import { useGlobalsStore } from '@/stores/globals';
import { useAlgoliaFilters } from '@/composables/algolia-filters';
import { getSelectValue } from '@/utils/blocks';
import FeaturedBlockHeader from '~/components/global/components/featured/FeaturedBlockHeader.vue';
import CategoriesMenu from '~/components/global/components/categories-menu/CategoriesMenu.vue';
import FeaturedFaqsList from '~/components/global/components/featured/FeaturedFaqsList.vue';
import FeaturedPagination from '~/components/global/components/featured/FeaturedPagination.vue';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import type { SearchConfig } from '~/plugins/search';

const hasMenu = ref(false);
const props: SectionProps = defineProps(useSectionProps());
const globals = useGlobalsStore();
const config = useRuntimeConfig();
const { menuFilters } = useAlgoliaFilters();
const { client: searchClient } = useNuxtApp().$search as SearchConfig;
const predefinedFilters = computed(() => props.section.filters);
const faqsIndex = computed(() => config.public.algoliaFaqsIndex);
const searchCategoriesTree = computed(() => globals.searchSettings?.categories_tree);
const hitsPerPage = computed(() => {
  if (props.section.items_per_page && !props.section.unlimited_items_per_page) {
    return props.section.items_per_page;
  }
  return 9999;
});
const displayType = computed(() => getSelectValue(props.section.display_type, 'value', 'manual'));
const itemIds = computed(() => (props.section.items?.map((item) => item.id) || []).map((id) => `entry${id}`));

function transformFilters(items) {
  items = menuFilters(props.section.display_type?.value, items, props.section.filters, searchCategoriesTree.value);
  hasMenu.value = !props.section.hide_categories_filter && items?.length > 0;
  return items;
}

function transformItems(items) {
  if (displayType.value !== 'manual') {
    return items;
  }

  if (props.section.unlimited_items_per_page) {
    return items;
  }

  const orderedItems = items.map((item) => ({
    ...item,
    __position: itemIds.value.indexOf(item.objectID) + 1,
  }));

  return orderBy(orderedItems, (item) => item.__position, 'asc');
}
</script>
