<template>
  <div class="process-cover-image-h-slider">
    <client-only>
      <div class="process-cover-image-h-slider__inner">
        <div class="process-cover-image-h-slider__cols columns">
          <div class="column is-6 process-cover-image-h-slider__col is-image">
            <picture-basic
              v-if="coverImage"
              class="process-cover-image-h-slider__fig"
              v-bind="
                getPictureBindings(coverImage, {
                  contain: false,
                  ratio: null,
                  preset: 'cover',
                })
              "
            />
          </div>
          <div class="column is-6 process-cover-image-h-slider__col is-content">
            <div class="process-cover-image-h-slider__col-inner" :class="sliderClasses">
              <swiper
                :slides-per-view="1"
                direction="horizontal"
                effect="fade"
                :allow-touch-move="false"
                :grab-cursor="false"
                :space-between="0"
                :loop="false"
                :fade-effect="{
                  crossFade: true,
                }"
                :navigation="{
                  nextEl: nextBtnEl,
                  prevEl: prevBtnEl,
                }"
                :breakpoints="{
                  [BREAKPOINTS.desktop]: {
                    allowTouchMove: true,
                  },
                }"
                :modules="[EffectFade, Navigation]"
              >
                <swiper-slide v-for="(item, index) in items" :key="index" class="process-cover-image-h-slider__slide">
                  <process-cover-image-h-slide :item="item" :number="getNumberByIndex(index)" :text-size="textSize" />
                </swiper-slide>
              </swiper>

              <div v-show="items.length > 1" class="process-cover-image-h-slider__btns">
                <pt-slider-btn ref="prevBtnRef" side-to="left" class="process-cover-image-h-slider__btn-prev" />
                <pt-slider-btn ref="nextBtnRef" side-to="right" class="process-cover-image-h-slider__btn-next" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectFade, Navigation } from 'swiper/modules';
import ProcessCoverImageHSlide from '@/components/global/components/process/ProcessCoverImageHSlide.vue';
import PtSliderBtn from '@/components/global/elements/PtSliderBtn.vue';
import { BREAKPOINTS } from '@/constants/layout';
import PictureBasic from '@/components/global/images/PictureBasic.vue';

const props = defineProps({
  coverImage: {
    type: Object,
    default: null,
  },
  items: {
    type: Array,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  background: {
    type: String,
    default: '',
  },
  displayNumbers: {
    type: String,
    default: 'default',
  },
  textSize: {
    type: String,
    default: 'default',
  },
});

const prevBtnRef = ref(null);
const nextBtnRef = ref(null);
const prevBtnEl = computed(() => prevBtnRef?.value?.el ?? null);
const nextBtnEl = computed(() => nextBtnRef?.value?.el ?? null);

const sliderClasses = computed(() => {
  const classes = [];

  if (props.background) {
    classes.push(`has-bg-${props.background}-light`);
  }

  return classes;
});

const getNumberByIndex = (index: any) => {
  switch (props.displayNumbers) {
    case 'none':
      return null;
    case 'second':
      return index === 0 ? null : index;
    case 'second-alt':
      return index === 0 ? null : index + 1;
    default:
      return index + 1;
  }
};
</script>
