<template>
  <div class="block-hero-cover-image">
    <div class="block-hero-cover-image__inner" :class="rootClasses">
      <div class="is-image">
        <picture-basic
          v-if="section.image"
          class="block-hero-cover-image__fig"
          v-bind="getPictureBindings(section.image, { ratio: null, preset: 'cover' })"
        />
      </div>
      <div class="block-hero-cover-image__content-wrap container">
        <div class="block-hero-cover-image__content">
          <div class="block-hero-cover-image__info-wrap">
            <div v-if="showInfo" class="block-hero-cover-image__info">
              <component :is="titleTag" v-if="section.title" class="block-hero-cover-image__info-title title is-1">
                {{ section.title }}
              </component>
              <bard v-if="section.description" :as-content="true" :content="section.description" class="block-hero-cover-image__info-description" />
              <div v-if="buttons.length" class="block-hero-cover-image__info-buttons">
                <pt-buttons>
                  <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
                    {{ button.label }}
                  </pt-button>
                </pt-buttons>
              </div>
            </div>
          </div>

          <button v-if="section.video" class="block-hero-cover-image__button" @click="handleVideoClick">
            <span v-if="section.embed_url" class="block-hero-cover-image__button-text">Play video</span>
            <video-basic v-if="section.video" class="block-hero-cover-image__video" v-bind="getVideoBindings(section.video, { contained: false })" />
            <span v-if="section.embed_url" class="block-hero-cover-image__button-icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.0002 7L2.3335 14L2.3335 0L14.0002 7Z" fill="#0A194D" />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSectionButtons, getSelectValue } from '@/utils/blocks';
import { EMBED_VIDEO_POPUP } from '@/constants/popups';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useButtons } from '~/composables/buttons';
import { useImages } from '~/composables/images';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import Bard from '~/components/content/Bard.vue';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import VideoBasic from '~/components/global/videos/VideoBasic.vue';
import { usePages } from '~/composables/pages';
import { useFeaturedVideoStore } from '~/stores/featured-video';
import { usePopupsStore } from '~/stores/popups';
import { useVideos } from '~/composables/videos';

const props: SectionProps = defineProps(useSectionProps());

const { setCurrentVideo } = useFeaturedVideoStore();
const { openPopup } = usePopupsStore();
const { getButtonBindings } = useButtons();
const { getPictureBindings } = useImages();
const { getVideoBindings } = useVideos();
const { displayPageTitle, displayBreadcrumbs, displayNavigation, sections } = usePages();

const rootClasses = computed(() => {
  const classes = [];
  const opacity = props.section.opacity;
  const video = props.section.video;
  const index = sections.value.findIndex(({ id }) => id === props.section.id);
  if (!displayNavigation.value && !displayBreadcrumbs.value && !displayPageTitle.value && index === 0) {
    classes.push('is-hide-header-nav');
  }
  if (opacity) {
    classes.push('is-opacity');

    const opacityOptions = props.section.opacity_options || {};
    const overlayColor = opacityOptions.overlay_color ? getSelectValue(opacityOptions.overlay_color, 'value', 'cream') : 'cream';
    const overlayOpacity = opacityOptions.overlay_percentage ? getSelectValue(opacityOptions.overlay_percentage, 'value', 30) : 30;
    const textColor = opacityOptions.text_color ? getSelectValue(opacityOptions.text_color, 'value', 'blue') : 'blue';
    const textColorMobile = opacityOptions.text_color_mobile ? getSelectValue(opacityOptions.text_color_mobile, 'value', 'blue') : 'blue';

    classes.push(`is-overlay-${overlayColor}`, `is-overlay-opacity-${overlayOpacity}`, `is-text-${textColor}`, `is-text-mob-${textColorMobile}`);
  }
  if (!video) {
    classes.push('is-no-video');
  }
  return classes;
});

const buttons = computed(() => getSectionButtons(props.section.buttons));
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h1'));
const showInfo = computed(() => props.section.title || props.section.description || props.section.buttons.length);

const handleVideoClick = () => {
  if (!props.section.embed_url) {
    return;
  }

  setCurrentVideo(props.section.embed_url);
  openPopup(EMBED_VIDEO_POPUP);
};
</script>
