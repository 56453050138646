<template>
  <div
    class="pt-slider"
    :class="{
      'is-1-slide': items.length === 1,
      'is-2-slide': items.length === 2,
      container: items.length <= 2,
    }"
  >
    <client-only>
      <swiper v-bind="swiperOptions">
        <swiper-slide v-for="(item, index) in items" :key="index" class="pt-slider__slide">
          <slot :item="item" :number="index + 1" />
        </swiper-slide>
      </swiper>

      <template v-if="navigation">
        <button class="pt-slider__btn is-prev" :class="`btn-prev-${name}`">
          <pt-icon-chevron-right />
        </button>
        <button class="pt-slider__btn is-next" :class="`btn-next-${name}`">
          <pt-icon-chevron-right />
        </button>
      </template>
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Mousewheel, Navigation } from 'swiper/modules';
import PtIconChevronRight from '@/components/global/icons/PtIconChevronRight.vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  navigation: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
});

const swiperOptions = computed(() => {
  return {
    slidesPerView: 'auto',
    spaceBetween: 0,
    loop: false,
    mousewheel: {
      enabled: true,
      forceToAxis: true,
    },
    navigation: {
      nextEl: `.btn-next-${props.name}`,
      prevEl: `.btn-prev-${props.name}`,
    },
    modules: [Mousewheel, Navigation],
    ...props.options,
  };
});
</script>
