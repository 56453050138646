<template>
  <div class="content-row-image">
    <div class="content-row-image__inner">
      <picture-basic v-if="row.image" v-bind="imageBindings" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import { getImageRatio, getPictureBindings } from '@/utils/images';
import { useContentRowProps } from '~/composables/blocks';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import type { ContentRowProps } from '~/types/components';

const props: ContentRowProps = defineProps(useContentRowProps());

const imageBindings = computed(() => {
  return getPictureBindings(props.row.image, {
    ratio: getImageRatio(getSelectValue(props.row.image_ratio, 'value'), props.row.image.ratio),
    preset: 'full',
    zoomable: props.row.is_image_zoomable,
    displayCaption: props.row.display_caption,
    displayCredit: props.row.display_credit,
  });
});
</script>
