<template>
  <blockquote class="testimonial-row-cards-slide-item">
    <svg class="testimonial-row-cards-slide-item__icon" width="58" height="44" viewBox="0 0 58 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2884 1.24382C5.0027 8.55124 0 17.8799 0 28.1413C0 38.2473 6.56604 44 13.9137 44C20.6361 44 26.2641 38.4028 26.2641 31.7173C26.2641 25.0318 21.5741 20.523 15.6334 20.523C14.3827 20.523 12.9757 20.6784 12.5067 20.9894C13.4447 17.1025 16.5714 12.5936 20.3234 9.17315C21.4178 8.0848 22.1995 6.84099 22.1995 5.13074C22.1995 2.33215 19.6981 0 16.7278 0C15.3208 0 14.0701 0.466434 13.2884 1.24382ZM45.0243 1.24382C36.7385 8.55124 31.8922 17.8799 31.8922 28.1413C31.8922 38.2473 38.3019 44 45.6496 44C52.5283 44 58 38.4028 58 31.7173C58 25.0318 53.31 20.523 47.5256 20.523C46.2749 20.523 44.8679 20.6784 44.3989 20.9894C45.1806 17.1025 48.4636 12.5936 52.2156 9.17315C53.31 8.0848 53.9353 6.84099 53.9353 5.13074C53.9353 2.33215 51.5903 0 48.6199 0C47.2129 0 45.9623 0.466434 45.0243 1.24382Z"
        fill="#DC291E"
      />
    </svg>
    <p class="testimonial-row-cards-slide-item__quote" :class="{ 'is-smaller': isSmaller }">
      {{ item.testimonial }}
    </p>
    <address v-if="!hideAuthor" class="testimonial-row-cards-slide-item__author">
      {{ item.title }}
    </address>
    <p v-if="item.sub_title" class="testimonial-row-cards-slide-item__sub">
      {{ item.sub_title }}
    </p>
  </blockquote>
</template>

<script lang="ts" setup>
import isArray from 'lodash/isArray';

const props = defineProps({
  testimonial: {
    type: [Array, Object],
    required: true,
  },
});

const item = computed(() => (isArray(props.testimonial) ? props.testimonial[0] : props.testimonial));
const hideAuthor = computed(() => !!item.value.hide_author);
const isSmaller = computed(() => `${item.value.testimonial}`.length > 250);
</script>
