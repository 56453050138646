<template>
  <div id="timeline" class="block-history-timeline">
    <h3 class="block-history-timeline__title title is-3">
      {{ section.title }}
    </h3>
    <div class="block-history-timeline__inner">
      <div class="block-history-timeline__content">
        <client-only>
          <div class="block-history-timeline__slider">
            <swiper
              slides-per-view="auto"
              :space-between="0"
              :loop="false"
              :navigation="{
                prevEl: prevBtnRef,
                nextEl: nextBtnRef,
              }"
              :modules="[Navigation]"
              @swiper="swiperInit"
              @slide-change="slideChange"
            >
              <swiper-slide v-for="item in events" :key="item.year" class="block-history-timeline__slide">
                <timeline-slide :item="item" />
              </swiper-slide>
            </swiper>

            <div class="block-history-timeline__arrows">
              <button ref="prevBtnRef" class="block-history-timeline__arrow is-prev swiper-button-prev" aria-label="Previous">
                <pt-icon-chevron-right />
              </button>
              <button ref="nextBtnRef" class="block-history-timeline__arrow is-next swiper-button-next" aria-label="Next">
                <pt-icon-chevron-right />
              </button>
            </div>

            <div v-if="swiperRef" ref="paginationRef" class="block-history-timeline__years">
              <div class="block-history-timeline__years-inner">
                <button
                  v-for="decade in decades"
                  :key="decade.year + decade.index"
                  class="block-history-timeline__year"
                  :class="{ 'is-active': activeSlide.year === decade.year }"
                  @click="slideTo(decade)"
                >
                  {{ decade.year }}
                </button>
              </div>
            </div>
          </div>
        </client-only>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import TimelineSlide from '~/components/global/components/timeline/TimelineSlide.vue';
import PtIconChevronRight from '~/components/global/icons/PtIconChevronRight.vue';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { usePopupsStore } from '~/stores/popups';
import { useTimelineStore } from '~/stores/timeline';

const props: SectionProps = defineProps(useSectionProps());
const popupsStore = usePopupsStore();
const timelineStore = useTimelineStore();
const activeSlide = ref(null);
const swiperRef = ref(null);
const paginationRef = ref(null);
const prevBtnRef = ref(null);
const nextBtnRef = ref(null);

const getDecade = (year) => {
  return Math.floor(year / 10) * 10;
};

const slideTo = (decade) => {
  swiperRef.value?.slideTo(decade.index);
};

const slideChange = (swiper) => {
  let index = 0;
  if (swiper.isEnd) {
    index = events.value.length - 1;
  } else {
    index = swiper.activeIndex;
  }

  activeSlide.value = {
    index,
    year: getDecade(events.value?.[index]?.year),
  };

  nextTick(() => {
    const pagination = paginationRef.value;
    if (!pagination) return;

    const active = pagination.querySelector('.block-history-timeline__year.is-active');
    if (!active) return;

    pagination.scroll({
      left: active.offsetLeft - pagination.offsetLeft,
      behavior: 'smooth',
    });
  });
};

const swiperInit = (swiper) => {
  swiperRef.value = swiper;
  slideChange(swiper);
};

const events = computed(() => {
  const years = [];
  const events = props.section?.events ?? [];
  for (let i = 0; i < events.length; i++) {
    const year = years.find((year) => year.year === events[i].year);
    if (year) {
      year.events.push(events[i]);
    } else {
      years.push({
        year: events[i].year,
        events: [events[i]],
      });
    }
  }
  return years;
});

const decades = computed(() => {
  const decades = [];
  events.value?.forEach((event, index) => {
    const decade = getDecade(event.year);
    if (!decades.find((value) => value.year === decade)) {
      decades.push({
        year: decade,
        index,
      });
    }
  });
  return decades;
});

onMounted(() => {
  timelineStore.setEvents(props.section.events);
});

onBeforeUnmount(() => {
  popupsStore.closePopup();
});
</script>
