<template>
  <div
    class="pricing-tabbed-item"
    :class="[{ 'is-active': item.title === activeTab, 'is-full': isFull, 'has-discount': hasDiscount }, `is-colour-${itemColour}`]"
  >
    <div class="pricing-tabbed-item__prices">
      <span v-if="discountLabel" class="pricing-tabbed-item__prices-discount-label">
        {{ discountLabel }}
      </span>
      <template v-if="hasDiscount">
        <div class="pricing-tabbed-item__prices-top has-discount">
          <div class="pricing-tabbed-item__price">
            <span v-if="singlePrice" class="pricing-tabbed-item__price-item is-discount">
              <span v-if="isShowPriceCaption" class="pricing-tabbed-item__price-caption">{{ singlePriceCaption }}</span>
              <span class="pricing-tabbed-item__price-num">{{ singlePrice }}</span>
            </span>

            <span v-if="hasSingleDiscount" class="pricing-tabbed-item__price-item is-original">
              <span class="pricing-tabbed-item__price-caption">{{ originalPriceCaption }}</span>
              <span class="pricing-tabbed-item__price-num">{{ singlePriceOriginal }}</span>
            </span>
          </div>
          <div class="pricing-tabbed-item__price">
            <span v-if="couplePrice" class="pricing-tabbed-item__price-item is-discount">
              <span v-if="isShowPriceCaption" class="pricing-tabbed-item__price-caption">{{ couplePriceCaption }}</span>
              <span class="pricing-tabbed-item__price-num">{{ couplePrice }}</span>
            </span>
            <span v-if="hasCoupleDiscount" class="pricing-tabbed-item__price-item is-original">
              <span class="pricing-tabbed-item__price-caption">{{ originalPriceCaption }}</span>
              <span class="pricing-tabbed-item__price-num">{{ couplePriceOriginal }}</span>
            </span>
          </div>
        </div>
        <div v-if="discountDescription" class="pricing-tabbed-item__prices-discount-description">
          {{ discountDescription }}
        </div>
      </template>
      <template v-else>
        <div class="pricing-tabbed-item__prices-top has-original">
          <div class="pricing-tabbed-item__price">
            <span v-if="singlePrice">
              <span v-if="isShowPriceCaption" class="pricing-tabbed-item__price-caption">{{ singlePriceCaption }}</span>
              <span class="pricing-tabbed-item__price-num">{{ singlePrice }}</span>
            </span>
            <span v-if="couplePrice">
              <span v-if="isShowPriceCaption" class="pricing-tabbed-item__price-caption">{{ couplePriceCaption }}</span>
              <span class="pricing-tabbed-item__price-num">{{ couplePrice }}</span>
            </span>
          </div>
        </div>
      </template>

      <div class="pricing-tabbed-item__prices-subtitle">
        {{ item.subtitle }}
      </div>
      <div v-if="hasSecondaryButton || hasTabbedButton" class="has-marg-top-xxl">
        <pt-buttons>
          <pt-button v-if="hasSecondaryButton" v-bind="getButtonBindings(item, 'secondary_button_')">
            {{ item.secondary_button_label }}
          </pt-button>
          <pt-button v-if="hasTabbedButton" v-bind="getButtonBindings(item, 'tabbed_button_')">
            {{ item.tabbed_button_label }}
          </pt-button>
        </pt-buttons>
      </div>
    </div>

    <div class="pricing-tabbed-item__content-wrap">
      <div class="pricing-tabbed-item__content" :class="{ 'is-open': isOpen }" :style="contentStyles">
        <div ref="pricingItemContent" class="pricing-tabbed-item__content-inner">
          <bard v-if="item.description_column_1" :as-content="true" :content="item.description_column_1" class="has-pad-bottom-l" />
          <bard v-if="item.description_column_2" :as-content="true" :content="item.description_column_2" class="has-pad-bottom-l" />
          <bard v-if="item.description_column_3" :as-content="true" :content="item.description_column_3" class="has-pad-bottom-l" />
        </div>
      </div>

      <div v-if="!isFull" class="pricing-tabbed-item__toggle pricing-tabbed-item__toggle-tablet">
        <button v-if="isCollapsedContent" class="pricing-tabbed-item__toggle-button" :class="{ 'is-open': isOpen }" @click="setOpen(!isOpen)">
          {{ isOpen ? 'Close' : 'View full pricing information and addons' }}
          <pt-icon-chevron-right />
        </button>
      </div>
    </div>

    <div v-if="!isFull" class="pricing-tabbed-item__toggle pricing-tabbed-item__toggle-mobile">
      <button v-if="isCollapsedContent" class="pricing-tabbed-item__toggle-button" :class="{ 'is-open': isOpen }" @click="setOpen(!isOpen)">
        {{ isOpen ? 'Close' : 'View full pricing information and addons' }}
        <pt-icon-chevron-right />
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PtIconChevronRight from '@/components/global/icons/PtIconChevronRight.vue';
import PtButtons from '@/components/global/elements/PtButtons.vue';
import PtButton from '@/components/global/elements/PtButton.vue';
import { getSelectValue } from '@/utils/blocks';
import { useButtons } from '#imports';
import Bard from '~/components/content/Bard.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  activeTab: {
    type: String,
    required: true,
  },
  isFull: {
    type: Boolean,
    default: false,
  },
});

const { getButtonBindings } = useButtons();

const pricingItemContent = ref(null);

const isOpen = ref(false);
const infoBlockHeight = ref(0);

const isShowPriceCaption = computed(() => props.item.single_price && props.item.couple_price);

const itemColour = computed(() => getSelectValue(props.item.colour, 'value', 'peach'));

const isCollapsedContent = computed(() => infoBlockHeight.value > 300 && !props.isFull);

const hasSecondaryButton = computed(() => props.item.secondary_button_label && props.item.secondary_button_link);
const hasTabbedButton = computed(() => props.item.tabbed_button_label && props.item.tabbed_button_link);

const contentStyles = computed(() => {
  return {
    '--infoHeight': props.isFull ? 'auto' : `${infoBlockHeight.value}px`,
  };
});

const hasDiscount = computed(() => props.item.discount && props.item.discount.has_discount === true);
const discountLabel = computed(() => (hasDiscount.value ? props.item.discount.discount_label : ''));
const discountDescription = computed(() => (hasDiscount.value ? props.item.discount.discount_description : ''));

const singlePrice = computed(() => {
  if (!hasDiscount.value) {
    return props.item.single_price;
  }
  return props.item.discount.single_price_discount || props.item.single_price;
});

const couplePrice = computed(() => {
  if (!hasDiscount.value) {
    return props.item.couple_price;
  }
  return props.item.discount.couple_price_discount || props.item.couple_price;
});

const hasSingleDiscount = computed(() => singlePrice.value && singlePriceOriginal.value && singlePrice.value !== singlePriceOriginal.value);
const hasCoupleDiscount = computed(() => couplePrice.value && couplePriceOriginal.value && couplePrice.value !== couplePriceOriginal.value);

const singlePriceOriginal = computed(() => props.item.single_price);
const couplePriceOriginal = computed(() => props.item.couple_price);

const singlePriceCaption = 'Individual';

const couplePriceCaption = 'Couple / Mirror';

const originalPriceCaption = 'Full price';

onMounted(() => {
  if (pricingItemContent.value && !props.isFull) {
    infoBlockHeight.value = pricingItemContent.value.getBoundingClientRect().height;
  }
});

onUpdated(() => {
  if (pricingItemContent.value && !props.isFull) {
    infoBlockHeight.value = pricingItemContent.value.getBoundingClientRect().height;
  }
});

const setOpen = (open) => {
  isOpen.value = open;
};
</script>
