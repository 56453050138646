<template>
  <div class="video-basic">
    <video class="video-basic__video" v-bind="videoAttrs">
      <source v-bind="{ src: url }" />
    </video>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  loop: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  url: {
    type: String,
    default: '',
  },
  contain: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [Number, String],
    default: '',
  },
  height: {
    type: [Number, String],
    default: '',
  },
  openPopup: {
    type: Boolean,
    default: true,
  },
});

const videoAttrs = computed(() => {
  const attrs = {
    playsinline: 'playsinline',
    muted: 'muted',
  };
  if (props.width) {
    attrs.width = props.width;
  }
  if (props.height) {
    attrs.height = props.height;
  }
  if (props.loop) {
    attrs.loop = 'loop';
  }
  if (props.autoplay) {
    attrs.autoplay = 'autoplay';
  }
  if (props.muted) {
    attrs.muted = 'muted';
  }
  return attrs;
});
</script>
