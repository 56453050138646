<template>
  <div class="block-text-sidebar" :class="rootClasses">
    <div class="block-text-sidebar__inner container is-narrow">
      <div class="block-text-sidebar__cols columns is-variable is-6-tablet is-8-desktop">
        <div class="block-text-sidebar__col column is-content" :class="`is-${12 - sidebarWidth}`">
          <article class="block-text-sidebar__content">
            <component :is="titleTag" v-if="section.title" class="block-text-sidebar__title title is-2 has-marg-bottom-s">
              {{ section.title }}
            </component>
            <bard :content="content" lead as-content primary-links class="block-text-sidebar__text has-p-links" />
          </article>
          <div v-if="hasContentButton" class="block-text-sidebar__sidebar-footer has-marg-top-m">
            <pt-button v-bind="getButtonBindings(contentButton)">
              {{ getButtonLabel(contentButton) }}
            </pt-button>
          </div>
        </div>
        <div class="block-text-sidebar__col column is-sidebar" :class="`is-${sidebarWidth}`">
          <aside class="block-text-sidebar__sidebar" :class="sidebarClasses">
            <component :is="sidebarTitleTag" v-if="section.sidebar_title" class="block-text-sidebar__sidebar-title title is-3 has-marg-bottom-s">
              {{ section.sidebar_title }}
            </component>
            <bard :content="sidebarContent" as-content lead primary-links class="block-text-sidebar__sidebar-text" />
            <div v-if="hasSidebarButton" class="block-text-sidebar__sidebar-footer has-marg-top-m">
              <pt-button v-bind="getButtonBindings(sidebarButton)">
                {{ getButtonLabel(sidebarButton) }}
              </pt-button>
            </div>
          </aside>
        </div>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script setup lang="ts">
import { getSelectValue } from '@/utils/blocks';
import PtButton from '~/components/global/elements/PtButton.vue';
import Bard from '~/components/content/Bard.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import { useBlob } from '~/composables/blob';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useButtons } from '~/composables/buttons';

const { getButtonLabel, getButtonBindings, getPrefixedButton } = useButtons();

const props: SectionProps = defineProps(useSectionProps());

const { getDisplayBlob, getBlobBindings } = useBlob();

const rootClasses = computed(() => {
  return {
    [`has-sidebar-position-${sidebarPosition.value}`]: true,
    'has-sidebar-border': sidebarHasBorder.value,
  };
});

const sidebarClasses = computed(() => {
  const classes = [];

  if (sidebarHasBorder.value) {
    classes.push('has-border');
  }

  if (sidebarAlign.value) {
    classes.push(`is-${sidebarAlign.value}`);
  }

  return classes;
});

const sidebarAlign = computed(() => getSelectValue(props.section.sidebar_align, 'value', 'centered'));

const sidebarPosition = computed(() => getSelectValue(props.section.sidebar_position, 'value', 'left'));

const sidebarWidth = computed(() => {
  const width = getSelectValue(props.section.sidebar_width, 'value', 'quarter');
  switch (width) {
    case 'fifth':
      return 2;
    case 'third':
      return 4;
    case 'half':
      return 6;
    default:
      return 3;
  }
});

const sidebarHasBorder = computed(() => !!props.section.sidebar_has_border);

const contentButton = computed(() => getPrefixedButton('button_', props.section));

const sidebarButton = computed(() => getPrefixedButton('sidebar_button_', props.section));

const hasSidebarButton = computed(() => !!sidebarButton.value?.link);

const hasContentButton = computed(() => !!contentButton.value?.link);

const content = computed(() => props.section.content);

const sidebarContent = computed(() => props.section.sidebar_content);

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));

const sidebarTitleTag = computed(() => getSelectValue(props.section.sidebar_title_size_select, 'value', 'h3'));
</script>
