<template>
  <div class="block-accordion">
    <div class="block-accordion__inner container is-narrower">
      <pt-section-header :title="section.title" :description="section.description" :title-tag="titleTag" class="has-marg-bottom-m" />
      <accordion class="block-accordion__items" :items="items" />
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import Accordion from '~/components/global/components/accordion/Accordion.vue';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();

const items = computed(() => props.section.items || []);
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
</script>
