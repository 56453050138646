<template>
  <div class="block-pricing" :class="{ 'is-variant-tabbed': isTabbedVariant }">
    <div class="block-pricing__inner container">
      <div class="block-pricing__border" :class="{ 'pad-border is-secondary': !isTabbedVariant }">
        <header class="block-pricing__header has-marg-bottom-l">
          <pt-section-header :title="section.title" :title-tag="titleTag" :description="section.description" />
          <div v-if="isFull && buttons.length" class="block-pricing__buttons has-marg-top-m has-marg-bottom-xl">
            <pt-buttons centered>
              <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
                {{ button.label }}
              </pt-button>
            </pt-buttons>
          </div>
        </header>
        <template v-if="isTabbedVariant">
          <pricing-tabbed :pricing-items="section.pricing_items" :is-full="isFull" />
          <p v-if="section.disclaimer" class="block-pricing__disclaimer has-marg-top-md">
            {{ section.disclaimer }}
          </p>
        </template>
        <template v-else>
          <div v-if="section.pricing_items" class="block-pricing__pricing">
            <pricing-full v-if="isFull" :pricing-items="section.pricing_items" />
            <pricing-short v-if="isShort" :pricing-items="section.pricing_items" />
          </div>
          <div v-if="isShort && buttons.length" class="block-pricing__buttons has-marg-top-l">
            <pt-buttons centered>
              <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
                {{ button.label }}
              </pt-button>
            </pt-buttons>
          </div>
        </template>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { getSectionButtons, getSelectValue } from '@/utils/blocks';
import PricingFull from '@/components/global/components/pricing/PricingFull';
import PricingShort from '@/components/global/components/pricing/PricingShort';
import PricingTabbed from '@/components/global/components/pricing/PricingTabbed.vue';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import { useButtons } from '~/composables/buttons';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();
const { getButtonBindings } = useButtons();

const buttons = computed(() => getSectionButtons(props.section.buttons));
const displayType = computed(() => getSelectValue(props.section.display_type));
const isShort = computed(() => displayType.value === 'short');
const isFull = computed(() => !isShort.value);
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
const isTabbedVariant = computed(() => getSelectValue(props.section.variant) === 'tabbed');
</script>
