export const useAlgoliaFilters = () => {
  const categoriesMenuFilters = (categories, selectedCategories) => {
    categories = categories.filter((category) => {
      return selectedCategories.includes(category.value + ' > ') || selectedCategories.includes(`categories.lvl0:"${category.value}"`);
    });
    return categories.map((category) => {
      const childCategories = category.data;
      if (childCategories) {
        const newChildCategories = childCategories.filter((childCategory) => selectedCategories.includes(childCategory.value));
        if (newChildCategories.length !== 0) {
          category.data = newChildCategories;
        }
        return category;
      }

      return category;
    });
  };

  const categoriesMenuOrder = (items, searchCategoriesTree) => {
    const { lvl0, lvl1 } = searchCategoriesTree;

    if (!lvl0) {
      return items;
    }

    return items
      .sort(({ value: a }, { value: b }) => lvl0.indexOf(a) - lvl0.indexOf(b))
      .map((category) => {
        if (category.data && lvl1) {
          category.data = category.data.sort(({ value: a }, { value: b }) => lvl1.indexOf(a) - lvl1.indexOf(b));
        }

        return category;
      });
  };
  const menuFilters = (type, items, filters, searchCategoriesTree) => {
    const BY_CATEGORY = 'by_category';

    if (type === BY_CATEGORY && filters && searchCategoriesTree) {
      items = categoriesMenuFilters(items, filters);
    }

    items = categoriesMenuOrder(items, searchCategoriesTree);

    return items;
  };

  return {
    categoriesMenuFilters,
    categoriesMenuOrder,
    menuFilters,
  };
};
