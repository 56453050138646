<template>
  <div class="block-locations">
    <div class="block-locations__inner container is-map">
      <div class="block-locations__cols">
        <div class="block-locations__col is-content">
          <div class="block-locations__content">
            <component :is="titleTag" class="block-locations__title title is-2 has-marg-bottom-s">
              {{ section.title }}
            </component>
            <bard v-if="section.description" :content="section.description" as-content primary-links class="block-locations__text" />
            <div v-if="!disableBookAppointment" class="block-locations__cta has-marg-top-xs">
              <pt-link-action href="#book" class="button-link is-primary" action="book"> Book an appointment </pt-link-action>
            </div>
            <locations-list
              ref="locationsListEl"
              class="block-locations__locations has-marg-top-l"
              :selected="selected"
              :locations="locations"
              @region-change="onRegionChange"
            />
          </div>
        </div>
        <div class="block-locations__col is-map">
          <locations-map :loading="sectionIndex ? 'lazy' : null" class="block-locations__map" :selected="selected" @region-click="onMapRegionClick" />
        </div>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import LocationsList from '@/components/global/components/locations/LocationsList.vue';
import LocationsMap from '~/components/global/components/locations/LocationsMap.vue';
import PtLinkAction from '~/components/global/elements/PtLinkAction.vue';
import Bard from '~/components/content/Bard.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import { ensureArrayValue, getSelectValue } from '@/utils/blocks';
import { useBlob } from '~/composables/blob';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();

const locationsListEl = ref(null);

const selected = ref('auckland');
const locations = computed(() => ensureArrayValue(props.section.locations));
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
const disableBookAppointment = computed(() => !!props.section.disable_book_appointment);

const onMapRegionClick = (region) => {
  selected.value = region;
  if (locationsListEl.value) {
    locationsListEl.value.setRegion(region);
  }
};

const onRegionChange = (region) => {
  selected.value = region;
};
</script>
