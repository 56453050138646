<template>
  <div class="process-boxed-slide" :class="{ 'has-bg': Boolean(background) }">
    <div class="process-boxed-slide__inner">
      <span class="process-boxed-slide__number title is-3">{{ item.number }}</span>
      <span v-if="isEven && !isFirst" class="process-boxed-slide__line is-left">
        <PtIconProcessLineLeft />
      </span>
      <span v-if="isEven && !isLast" class="process-boxed-slide__line is-right">
        <PtIconProcessLineRight class="is-line-mobile" />
        <PtIconProcessLineRightDesktop class="is-line-desktop" />
      </span>
      <div class="columns is-variable" :class="{ 'is-only-image': isOnlyImage }">
        <div v-if="image" class="process-boxed-slide__col is-image column is-12 is-5-desktop">
          <picture-basic
            class="process-boxed-slide__fig"
            :class="bgClasses"
            v-bind="getPictureBindings(image, { ratio: null, contain: !imageCover })"
          />
        </div>
        <div class="process-boxed-slide__col is-text column is-12" :class="{ 'is-7-desktop': image }">
          <div class="process-boxed-slide__content">
            <div v-if="title" class="process-boxed-slide__title title is-4">
              {{ title }}
            </div>
            <bard :content="description" :as-content="true" class="process-boxed-slide__description" />
            <div v-if="hasCta" class="feature-item__cta lead-label is-strong">
              <pt-button v-bind="getButtonBindings(item as any, 'button_')">
                {{ ctaLabel }}
              </pt-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PictureBasic from '@/components/global/images/PictureBasic.vue';
import PtButton from '@/components/global/elements/PtButton.vue';
import PtIconProcessLineLeft from '@/components/global/icons/PtIconProcessLineLeft.vue';
import PtIconProcessLineRight from '@/components/global/icons/PtIconProcessLineRight.vue';
import PtIconProcessLineRightDesktop from '@/components/global/icons/PtIconProcessLineRightDesktop.vue';
import { useButtons } from '~/composables/buttons';
import Bard from '~/components/content/Bard.vue';
import { useImages } from '~/composables/images';

const props = defineProps({
  count: {
    type: Number,
    required: true,
  },
  item: {
    type: Object,
    required: true,
  },
  background: {
    type: String,
    default: '',
  },
});

const { getButtonBindings } = useButtons();
const { getPictureBindings } = useImages();

const bgClasses = computed(() => {
  const classes = [];

  if (props.background) {
    classes.push(`has-bg-${props.background}-light`);
  }

  if (imageCover.value) {
    classes.push('is-cover');
  }

  return classes;
});

const title = computed(() => props.item.title || null);
const description = computed(() => props.item.description || null);
const image = computed(() => props.item.image || null);
const imageCover = computed(() => props.item.image_cover || false);
const hasCta = computed(() => props.item.button_link && props.item.button_label);
const ctaLabel = computed(() => props.item.button_label);
const isOnlyImage = computed(() => !title.value && !description.value && !hasCta.value);
const isEven = computed(() => props.item.number % 2 === 0);
const isLast = computed(() => props.item.number === props.count);
const isFirst = computed(() => props.item.number - 1 === 0);
</script>
