<template>
  <div class="image-text" :class="`is-${type}`">
    <div class="image-text__cols columns is-variable is-6-tablet is-7-desktop is-9-widescreen">
      <div class="image-text__col is-image column is-6 is-5-widescreen">
        <picture-basic v-if="block.image" v-bind="imageBindings" class="image-text__fig" :class="{ 'has-border': !!block.image_border }" />
      </div>
      <div class="image-text__col is-text column is-6 is-7-widescreen">
        <div class="image-text__content">
          <component :is="titleTag" v-if="block.title" class="image-text__title title is-3">
            {{ block.title }}
          </component>
          <bard v-if="block.text" :content="block.text" :as-content="true" class="image-text__text" />
          <div v-if="hasCta" class="image-text__cta">
            <pt-button-link :to="block.link">
              {{ block.label }}
            </pt-button-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import { getImageRatio, getPictureBindings } from '@/utils/images';
import Bard from '~/components/content/Bard.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import PtButtonLink from '~/components/global/elements/PtButtonLink.vue';

const props = defineProps({
  block: {
    type: Object,
    required: true,
  },
});

const type = computed(() => getSelectValue(props.block.type, 'value', 'image_text'));
const titleTag = computed(() => getSelectValue(props.block.title_size_select, 'value', 'h3'));
const hasCta = computed(() => !!props.block.has_cta && props.block.link && props.block.label);

const imageBindings = computed(() => {
  return getPictureBindings(props.block.image, {
    ratio: getImageRatio(getSelectValue(props.block.image_ratio, 'value')),
    zoomable: props.block.is_image_zoomable,
    displayCaption: props.block.display_caption,
    displayCredit: props.block.display_credit,
  });
});
</script>
