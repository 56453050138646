<template>
  <div class="block-page-anchors">
    <client-only>
      <sticky :offset-top="stickyOffsetTop">
        <div class="block-page-anchors__container">
          <div class="block-page-anchors__inner container is-fluid">
            <div
              :key="scrollSpyKey"
              ref="scrollSpy"
              v-scroll-spy-active="scrollSpyActive"
              v-scroll-spy-link="scrollSpyLink"
              class="block-page-anchors__scroll"
              :class="{ 'is-without-slider': fitsWithoutSlider }"
            >
              <swiper
                :a11y="{
                  prevSlideMessage: 'Previous anchor',
                  nextSlideMessage: 'Next anchor',
                  enabled: true,
                }"
                slides-per-view="auto"
                :space-between="0"
                :watch-overflow="true"
                :centered-slides="false"
                :loop="false"
                :free-mode="true"
                :modules="[A11y, Navigation]"
                @swiper="handleSwiperReady"
                @slide-change="handleSwiperResize"
                @progress="handleSwiperProgress"
              >
                <swiper-slide v-for="(anchor, index) in anchors" :key="`anchor-${index}`" class="block-page-anchors__slide">
                  <page-anchor :item="anchor" />
                </swiper-slide>
              </swiper>
            </div>
          </div>
          <button v-if="hasPrev" class="block-page-anchors__arrow is-prev" @click.prevent="prev()">
            <span class="visually-hidden">Prev</span>
            <pt-icon-chevron-right />
          </button>
          <button v-if="hasNext" class="block-page-anchors__arrow is-next" @click.prevent="next()">
            <span class="visually-hidden">Next</span>
            <pt-icon-chevron-right />
          </button>
        </div>
      </sticky>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { A11y, Navigation } from 'swiper/modules';
import { useWindowSize } from '@vueuse/core';
import { ensureArrayValue } from '@/utils/blocks';
import Sticky from '@/components/global/elements/PositionSticky';
import PageAnchor from '@/components/global/components/anchors/PageAnchor';
import PtIconChevronRight from '~/components/global/icons/PtIconChevronRight.vue';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { BREAKPOINTS } from '~/constants/layout';

const props: SectionProps = defineProps(useSectionProps());
const fitsWithoutSlider = ref(true);
const isBeginning = ref(false);
const isEnd = ref(false);
const stickyOffsetTop = ref(81);
const scrollSpyActive = ref(null);
const scrollSpyLink = ref(null);
const scrollSpyKey = ref(0);
const swiperInstance = ref(null);
const anchors = computed(() => ensureArrayValue(props.section.links));
const hasPrev = computed(() => !fitsWithoutSlider.value && !isBeginning.value);
const hasNext = computed(() => !fitsWithoutSlider.value && !isEnd.value);
const { width, height } = useWindowSize();

const handleSwiperReady = (instance) => {
  swiperInstance.value = instance;

  handleSwiperResize(instance);
};

const handleSwiperProgress = (instance) => {
  isBeginning.value = instance.isBeginning;
  isEnd.value = instance.isEnd;
};

const forceReRender = () => {
  scrollSpyKey.value += 1;
};

const prev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};

const next = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const updateStickyOffset = () => {
  if (width.value >= BREAKPOINTS.desktop) {
    stickyOffsetTop.value = 81;
  } else if (width.value >= BREAKPOINTS.tablet) {
    stickyOffsetTop.value = 69;
  } else {
    stickyOffsetTop.value = 58;
  }
};

const handleSwiperResize = (instance) => {
  nextTick(() => {
    if (!instance) return;
    fitsWithoutSlider.value = instance.virtualSize <= instance.size;
    isBeginning.value = instance.isBeginning;
    isEnd.value = instance.isEnd;
  });
};

const onResize = () => {
  updateStickyOffset();

  if (swiperInstance.value) {
    handleSwiperResize(swiperInstance.value);
  }
};

onMounted(() => {
  updateStickyOffset();
  scrollSpyActive.value = { selector: '.page-anchor', class: 'is-current' };
  scrollSpyLink.value = { selector: '.page-anchor a' };
  nextTick(() => {
    forceReRender();
  });
});

watch(width, () => onResize());
watch(height, () => onResize());
</script>
