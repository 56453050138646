<template>
  <div ref="el" :style="wrapperStyles">
    <div :class="[{ sticky: sticky }, stickyClass]" :style="stickyStyles">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useWindowScroll, useWindowSize } from '@vueuse/core';

const emit = defineEmits(['change']);

const props = defineProps({
  offsetTop: {
    type: Number,
    default: 0,
  },
  offsetBottom: {
    type: Number,
    default: undefined,
  },
  stickyClass: {
    type: String,
    default: '',
  },
});

const { y } = useWindowScroll();
const { width, height } = useWindowSize();

const el = ref(null);
const sticky = ref(false);
const wrapperStyles = ref(null);
const stickyStyles = ref(null);
const offsetType = computed(() => (props.offsetBottom >= 0 ? 'bottom' : 'top'));

const getScroll = (target: any, top: any) => target[top ? 'pageYOffset' : 'pageXOffset'];

const getOffset = (element: any) => {
  const rect = element.getBoundingClientRect();
  const scrollTop = getScroll(window, true);
  const scrollLeft = getScroll(window, false);

  const docEl = window.document.body;
  const clientTop = docEl.clientTop || 0;
  const clientLeft = docEl.clientLeft || 0;
  return {
    top: rect.top + scrollTop - clientTop,
    left: rect.left + scrollLeft - clientLeft,
    width: rect.width,
    height: rect.height,
  };
};

const handleScroll = () => {
  const scrollTop = getScroll(window, true);
  const elOffset = getOffset(el.value);
  const windowHeight = window.innerHeight;
  const elHeight = el.value.getElementsByTagName('div')[0].offsetHeight;

  // Fixed Top
  if (elOffset.top - props.offsetTop <= scrollTop && offsetType.value === 'top' && !sticky.value) {
    sticky.value = true;
    wrapperStyles.value = {
      width: `${elOffset.width}px`,
      'min-width': `${elOffset.width}px`,
      height: `${elOffset.height}px`,
      'min-height': `${elOffset.height}px`,
    };
    stickyStyles.value = {
      top: `${props.offsetTop}px`,
      left: `${elOffset.left}px`,
      width: `${el.value.offsetWidth}px`,
    };
    emit('change', true);
  } else if (elOffset.top - props.offsetTop > scrollTop && offsetType.value === 'top' && sticky.value) {
    sticky.value = false;
    wrapperStyles.value = null;
    stickyStyles.value = null;
    emit('change', false);
  }

  // Fixed Bottom
  if (elOffset.top + props.offsetBottom + elHeight > scrollTop + windowHeight && offsetType.value === 'bottom' && !sticky.value) {
    sticky.value = true;
    wrapperStyles.value = {
      width: `${elOffset.width}px`,
      'min-width': `${elOffset.width}px`,
      height: `${elOffset.height}px`,
      'min-height': `${elOffset.height}px`,
    };
    stickyStyles.value = {
      bottom: `${props.offsetBottom}px`,
      left: `${elOffset.left}px`,
      width: `${el.value.offsetWidth}px`,
    };
    emit('change', true);
  } else if (elOffset.top + props.offsetBottom + elHeight < scrollTop + windowHeight && offsetType.value === 'bottom' && sticky.value) {
    sticky.value = false;
    wrapperStyles.value = null;
    stickyStyles.value = null;
    emit('change', false);
  }
};

const handleResize = () => {
  sticky.value = false;
  wrapperStyles.value = null;
  stickyStyles.value = null;

  nextTick(() => {
    handleScroll();
  });
};

watch(y, () => handleScroll());
watch(width, () => handleResize());
watch(height, () => handleResize());
//
// onMounted(() => {
//   // this.$bus.$on('scroll', this.handleScroll);
//   // this.$bus.$on('resize', this.handleResize);
// });
//
// onBeforeUnmount(() => {
//   // this.$bus.$off('scroll', this.handleScroll);
//   // this.$bus.$off('resize', this.handleResize);
// });
</script>
