<template>
  <component :is="computedTag" class="button-ext" v-bind="$attrs" type="button" :class="rootClasses">
    <div class="button-ext__inner">
      <pt-icon v-if="hasIcon && icon" class="button-ext__icon" :name="icon" :size="24" />
      <pt-icon-chevron-right v-if="hasArrow" class="button-ext__arrow" />
      <hr v-if="displayLine" class="button-ext__line" />
      <component :is="titleTag" class="button-ext__title">
        <template v-if="label">
          {{ label }}
        </template>
        <template v-else>
          <slot />
        </template>
      </component>
      <div v-if="description || $slots.description" class="button-ext__description">
        <p v-if="description">
          {{ description }}
        </p>
        <p v-else-if="$slots.description" />
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import isEmpty from 'lodash/isEmpty';
import AppLink from '~/components/AppLink';
import PtIconChevronRight from '~/components/global/icons/PtIconChevronRight.vue';
import PtIcon from '~/components/global/elements/PtIcon.vue';
import PtLinkAction from '~/components/global/elements/PtLinkAction.vue';

defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();

const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
  displayLine: {
    type: Boolean,
    default: false,
  },
  hasArrow: {
    type: Boolean,
    default: false,
  },
  hasIcon: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: null,
  },
  hasBorder: {
    type: Boolean,
    default: false,
  },
  textSize: {
    type: String,
    default: null,
  },
  tag: {
    type: String,
    default: 'button',
    validator: (value: string) => ['a', 'button', 'router-link', 'app-link', 'pt-link-action'].includes(value),
  },
  titleTag: {
    type: String,
    default: 'h4',
  },
});

const computedTag = computed(() => {
  if (isEmpty(attrs.to) && isEmpty(attrs.href)) {
    return 'div';
  }
  if (attrs.disabled !== undefined && attrs.disabled !== false) {
    return 'button';
  }
  switch (props.tag) {
    case 'pt-link-action':
      return PtLinkAction;
    case 'app-link':
      return AppLink;
    default:
      return props.tag;
  }
});

const rootClasses = computed(() => {
  const classes: any = [
    {
      'has-line': props.displayLine,
      'has-arrow': props.hasArrow,
      'has-icon': props.hasIcon,
      'has-border': props.hasBorder,
    },
  ];

  if (props.textSize) {
    classes.push(`is-${props.textSize}`);
  }

  return classes;
});
</script>
