<template>
  <div class="block-cta container">
    <div class="block-cta__inner">
      <div class="block-cta__columns columns" :class="innerClasses">
        <div
          class="block-cta__col is-text column is-12-mobile"
          :class="{
            'is-8-tablet': section.image,
            'is-7-desktop': section.image,
          }"
        >
          <div class="block-cta__content" :class="{ 'has-image': section.image }">
            <component :is="titleTag" class="block-image-text__title title is-2">
              {{ section.title }}
            </component>
            <bard class="block-cta__description lead-label has-marg-top-xs" :content="section.description" :as-content="true" />
            <div v-if="buttonCta.link" class="has-marg-top-s">
              <pt-button v-bind="getButtonBindings(buttonCta)">
                {{ buttonCta.label || 'Learn more' }}
              </pt-button>
            </div>
          </div>
        </div>
        <div v-if="section.image" class="block-cta__col is-image column is-12-mobile is-4-tablet is-5-desktop">
          <picture-basic v-bind="imageBindings" class="block-cta__fig" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import { useButtons } from '~/composables/buttons';
import { useImages } from '~/composables/images';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import Bard from '~/components/content/Bard.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';

const props: SectionProps = defineProps(useSectionProps());

const { getButtonBindings } = useButtons();
const { getPictureBindings } = useImages();

const innerClasses = computed(() => {
  const classes = [];
  const imagePositionMobile = getSelectValue(props.section.image_position_on_mobile);

  if (imagePositionMobile) {
    classes.push(`is-image-${imagePositionMobile}`);
  }

  return classes;
});

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));

const buttonCta = computed(() => ({
  link: props.section.button_cta_link,
  label: props.section.button_cta_label,
  width: props.section.button_cta_width,
  type: props.section.button_cta_type,
  outlined: props.section.button_cta_outlined,
}));

const imageBindings = computed(() => getPictureBindings(props.section.image, { ratio: null, contain: false, preset: 'full' }));
</script>
