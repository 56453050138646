<template>
  <div class="block-features-list">
    <div class="block-features-list__container container" :class="{ 'is-narrow': !isBoxedVariant }">
      <div class="block-features-list__inner">
        <pt-section-header
          :title="section.title"
          :title-tag="titleTag"
          :description="section.description"
          :slider-buttons="{
            enabled: isBoxedVariant,
            items: features,
            prevEl: `btn-prev-${section.id}`,
            nextEl: `btn-next-${section.id}`,
          }"
        />
        <features-list v-if="!isBoxedVariant" :items="features" class="has-marg-top-xl" />
      </div>
    </div>
    <pt-slider v-if="isBoxedVariant" :name="section.id" :items="features" :navigation="false" class="block-features-list__slider has-marg-top-xl">
      <template #default="item">
        <feature-slide :item="item.item" />
      </template>
    </pt-slider>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import { useBlob } from '~/composables/blob';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import FeaturesList from '~/components/global/components/features/FeaturesList.vue';
import FeatureSlide from '~/components/global/components/features/FeatureSlide.vue';
import PtSlider from '~/components/global/elements/PtSlider.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';

const props: SectionProps = defineProps(useSectionProps());

const { getDisplayBlob, getBlobBindings } = useBlob();

const features = computed(() => props.section.features || []);
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
const isBoxedVariant = computed(() => getSelectValue(props.section.variant) === 'boxed');
</script>
