<template>
  <div v-if="testimonials.length" :class="{ 'is-more-slides': testimonials.length > 1 }">
    <client-only>
      <swiper
        :a11y="{
          prevSlideMessage: 'Previous testimonial',
          nextSlideMessage: 'Next testimonial',
          enabled: true,
        }"
        slides-per-view="auto"
        :space-between="0"
        effect="fade"
        :fade-effect="{
          crossFade: true,
        }"
        :free-mode="false"
        :pagination="{
          el: paginationRef,
          type: 'fraction',
        }"
        :navigation="{
          prevEl: prevBtnEl,
          nextEl: nextBtnEl,
        }"
        :breakpoints="{
          [BREAKPOINTS.mobile]: {
            autoHeight: true,
          },
        }"
        :modules="[EffectFade, Pagination, Navigation, A11y]"
      >
        <swiper-slide v-for="(testimonial, index) in testimonials" :key="`testimonial-${index}`" class="block-testimonials__slide">
          <testimonial-slide-item :testimonial="testimonial" />
        </swiper-slide>
        <template #wrapper-end>
          <div v-if="testimonials.length > 1" class="block-testimonials__navigation">
            <pt-slider-btn ref="prevBtnRef" side-to="left" class="block-testimonials__navigation-prev" />
            <div ref="paginationRef" class="block-testimonials__pagination" />
            <pt-slider-btn ref="nextBtnRef" side-to="right" class="block-testimonials__navigation-next" />
          </div>
        </template>
      </swiper>
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectFade, Navigation, Pagination, A11y } from 'swiper/modules';
import PtSliderBtn from '@/components/global/elements/PtSliderBtn.vue';
import { BREAKPOINTS } from '@/constants/layout';
import TestimonialSlideItem from '~/components/global/components/testimonials/TestimonialSlideItem.vue';

defineProps({
  testimonials: {
    type: Array,
    required: true,
  },
});

const paginationRef = ref(null);
const prevBtnRef = ref(null);
const nextBtnRef = ref(null);
const prevBtnEl = computed(() => prevBtnRef?.value?.el ?? null);
const nextBtnEl = computed(() => nextBtnRef?.value?.el ?? null);
</script>
