<template>
  <div v-if="section.image">
    <picture-basic v-bind="getPictureBindings(section.image, { preset: 'full' })" />
  </div>
</template>

<script lang="ts" setup>
import { useSectionProps } from '~/composables/blocks';
import { useImages } from '~/composables/images';
import PictureBasic from '~/components/global/images/PictureBasic.vue';

defineProps(useSectionProps());

const { getPictureBindings } = useImages();
</script>
