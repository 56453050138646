<template>
  <div class="block-embed">
    <div class="block-embed__inner container" :class="innerClasses">
      <component :is="titleTag" v-if="section.title" class="block-image-text__title title is-2 is-centered has-marg-bottom-s">
        {{ section.title }}
      </component>
      <embed-block v-bind="embedBindings" />
      <div v-if="buttons.length" class="block-image-text__buttons has-marg-top-m">
        <pt-buttons centered>
          <pt-button v-for="(button, index) in buttons" :key="`button-${index}`" v-bind="getButtonBindings(button)">
            {{ button.label || 'Learn More' }}
          </pt-button>
        </pt-buttons>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { getSectionButtons, getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import { useButtons } from '~/composables/buttons';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import EmbedBlock from '~/components/global/components/embed/EmbedBlock.vue';
import PtButtons from '~/components/global/elements/PtButtons.vue';
import PtButton from '~/components/global/elements/PtButton.vue';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();
const { getButtonBindings } = useButtons();

const innerClasses = computed(() => {
  const classes = [];
  const width = getSelectValue(props.section.width);
  if (width) {
    classes.push(`is-${width}`);
  }
  return classes;
});

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));

const buttons = computed(() => getSectionButtons(props.section.buttons));

const embedBindings = computed(() => {
  const type = getSelectValue(props.section.embed_type);

  return {
    type,
    responsive: props.section.embed_responsive,
    ratio: getSelectValue(props.section.embed_ratio),
    code: type === 'video' ? props.section.embed_url : props.section.embed_code,
  };
});
</script>
