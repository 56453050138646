<template>
  <component :is="computedTag" class="button-menu" v-bind="$attrs" type="button">
    <span class="button-menu__label">
      <template v-if="label">
        {{ label }}
      </template>
      <template v-else>
        <slot />
      </template>
    </span>
    <span class="button-menu__arrow">
      <pt-icon-chevron-right />
    </span>
  </component>
</template>

<script lang="ts" setup>
import PtIconChevronRight from '~/components/global/icons/PtIconChevronRight.vue';
import AppLink from '~/components/AppLink';
import PtLinkAction from '~/components/global/elements/PtLinkAction.vue';

defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();

const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  tag: {
    type: String,
    default: 'button',
    validator: (value) => ['a', 'button', 'router-link', 'app-link', 'pt-link-action'].includes(value),
  },
});

const computedTag = computed(() => {
  if (attrs.disabled !== undefined && attrs.disabled !== false) {
    return 'button';
  }

  switch (props.tag) {
    case 'pt-link-action':
      return PtLinkAction;
    case 'app-link':
      return AppLink;
    default:
      return props.tag;
  }
});
</script>
