<template>
  <div v-if="testimonials.length" class="block-testimonials">
    <div :class="{ container: !isRowOfCardsVariant }">
      <pt-section-header
        class="block-testimonials__inner"
        :class="{ container: isRowOfCardsVariant }"
        :title="section.title"
        :title-tag="titleTag"
        :description="section.description"
      />
      <testimonials-row-cards-slider
        v-if="isRowOfCardsVariant"
        class="block-testimonials__row-cards-slider has-marg-top-m"
        :testimonials="testimonials"
      />
      <testimonials-slider v-else class="block-testimonials__slider has-marg-top-m" :testimonials="testimonials" />
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import TestimonialsRowCardsSlider from '@/components/global/components/testimonials/TestimonialsRowCardsSlider.vue';
import TestimonialsSlider from '@/components/global/components/testimonials/TestimonialsSlider.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import { useBlob } from '~/composables/blob';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();

const testimonials = computed(() => {
  const testimonials = props.section.testimonials || [];
  if (!testimonials.length) {
    return testimonials;
  }
  if (isRowOfCardsVariant.value) {
    let multiplier = 1;
    if (testimonials.length <= 1) {
      multiplier = 6;
    } else if (testimonials.length < 3) {
      multiplier = 3;
    } else if (testimonials.length < 6) {
      multiplier = 2;
    }
    return Array(multiplier).fill(testimonials).flat();
  }
  return testimonials;
});

const isRowOfCardsVariant = computed(() => getSelectValue(props.section.variant) === 'row_of_cards');
const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
</script>
