<template>
  <div class="block-content">
    <div class="block-content__inner container is-narrow">
      <content-row v-for="(row, index) in section.rows" :key="`row-${index}`" :row="row" :row-index="index" class="block-content__row" />
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script lang="ts" setup>
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import ContentRow from '~/components/content/content-rows/ContentRow.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';

defineProps(useSectionProps());

const { getDisplayBlob, getBlobBindings } = useBlob();
</script>
