<template>
  <div class="process-cover-image-h-slide">
    <div class="process-cover-image-h-slide__content">
      <span v-if="number" class="process-cover-image-h-slide__number">{{ number }}</span>
      <div class="process-cover-image-h-slide__info">
        <div v-if="title" class="process-cover-image-h-slide__title title" :class="textSize === 'large' ? 'is-2' : 'is-4'">
          {{ title }}
        </div>
        <bard
          :content="description"
          :as-content="true"
          class="process-cover-image-h-slide__description"
          :class="textSize === 'large' ? 'is-lead' : ''"
        />
        <div v-if="hasCta" class="process-cover-image-h-slide__cta lead-label is-strong">
          <pt-button v-bind="getButtonBindings(item, 'button_')">
            {{ ctaLabel }}
          </pt-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useButtons } from '~/composables/buttons';
import PtButton from '~/components/global/elements/PtButton.vue';
import Bard from '~/components/content/Bard.vue';

const { getButtonBindings } = useButtons();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  number: {
    type: Number,
    default: null,
  },
  textSize: {
    type: String,
    default: 'default',
  },
});

const title = computed(() => props.item.title || null);
const description = computed(() => props.item.description || null);
// const image = computed(() => props.item.image || null);
const hasCta = computed(() => props.item.button_link && props.item.button_label);
const ctaLabel = computed(() => props.item.button_label);
</script>
