<template>
  <div class="features-list">
    <div class="features-list__cols columns is-multiline is-variable is-6-tablet is-8-desktop is-centered">
      <div v-for="(item, index) in items" :key="`feature-${index}`" class="features-list__col column is-4">
        <feature-item :item="item" class="features-list__item" />
      </div>
    </div>
  </div>
</template>

<script>
import FeatureItem from './FeatureItem';

export default {
  components: {
    FeatureItem,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
