<template>
  <div v-if="testimonials.length" class="testimonials-row-cards-slider">
    <client-only>
      <swiper
        :a11y="{
          prevSlideMessage: 'Previous testimonial',
          nextSlideMessage: 'Next testimonial',
          enabled: true,
        }"
        :slides-per-view="2"
        :space-between="14"
        :watch-overflow="true"
        :centered-slides="true"
        :loop="true"
        :free-mode="false"
        :navigation="{
          prevEl: prevBtnRef,
          nextEl: nextBtnRef,
        }"
        :breakpoints="{
          [BREAKPOINTS.mobile]: {
            slidesPerView: 1.5,
            spaceBetween: 16,
          },
          [BREAKPOINTS.tablet]: {
            slidesPerView: 2.5,
            spaceBetween: 16,
          },
          [BREAKPOINTS.desktop]: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }"
        :modules="[Navigation, A11y]"
      >
        <swiper-slide v-for="(testimonial, index) in testimonials" :key="`testimonial-${index}`" class="testimonials-row-cards-slider__slide">
          <testimonial-row-cards-slide-item :testimonial="testimonial" />
        </swiper-slide>
        <template #container-end>
          <button ref="prevBtnRef" class="testimonials-row-cards-slider__arrow is-prev" aria-label="Previous">
            <svg width="119" height="208" viewBox="0 0 119 208" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M112.108 30.0996C118.451 23.465 118.214 12.9459 111.577 6.60457C104.942 0.263521 94.4208 0.501181 88.0782 7.13543L6.60592 92.3543C3.48874 95.6149 1.9599 99.8324 2.00087 104C1.9599 108.168 3.48875 112.385 6.60591 115.646L88.0782 200.865C94.4208 207.499 104.942 207.736 111.577 201.395C118.214 195.054 118.451 184.535 112.108 177.9L41.4571 104L112.108 30.0996Z"
                fill="white"
                stroke="#FFF6E7"
                stroke-width="4"
              />
            </svg>
          </button>
          <button ref="nextBtnRef" class="testimonials-row-cards-slider__arrow is-next" aria-label="Next">
            <svg width="119" height="208" viewBox="0 0 119 208" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.60587 177.9C0.263023 184.535 0.500781 195.054 7.13686 201.395C13.7727 207.736 24.2935 207.499 30.6361 200.865L112.108 115.646C115.226 112.385 116.754 108.168 116.713 104C116.754 99.8324 115.226 95.6149 112.108 92.3544L30.6361 7.13547C24.2935 0.501206 13.7727 0.263519 7.13686 6.60458C0.500774 12.9459 0.263029 23.4651 6.60587 30.0996L77.2573 104L6.60587 177.9Z"
                fill="white"
                stroke="#FFF6E7"
                stroke-width="4"
              />
            </svg>
          </button>
        </template>
      </swiper>
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, A11y } from 'swiper/modules';
import { BREAKPOINTS } from '@/constants/layout';
import TestimonialRowCardsSlideItem from '@/components/global/components/testimonials/TestimonialRowCardsSlideItem.vue';

defineProps({
  testimonials: {
    type: Array,
    required: true,
  },
});

const prevBtnRef = ref(null);
const nextBtnRef = ref(null);
</script>
