<template>
  <div class="pricing-cards">
    <div class="columns is-multiline is-mobile is-variable is-1 is-centered">
      <div v-for="(item, index) in pricingItems" :key="`p-${index}`" class="pricing-cards__col column is-12-mobile is-6-tablet is-3-widescreen">
        <pt-card fullheight border="grey" has-pad="xs">
          <pricing-card-header :item="item" fullheight :bg="headerColor(item)" />
        </pt-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import PtCard from '~/components/global/elements/PtCard.vue';
import PricingCardHeader from '~/components/global/components/pricing/PricingCardHeader.vue';

defineProps({
  pricingItems: {
    type: Array,
    required: true,
  },
});

const headerColor = (item) => `${getSelectValue(item.colour) || 'pink'}-light`;
</script>
