<template>
  <div class="block-epa">
    <div class="container">
      <div class="block-epa__inner">
        <div class="block-epa__inner-left">
          <h3 class="title is-3 is-strong">
            {{ section.epa_pricing.left_title }}
          </h3>
          <div
            v-if="section.epa_pricing.pricing_items && section.epa_pricing.pricing_items.length"
            class="block-epa__left-pricing has-marg-top-s columns is-multiline"
          >
            <div v-for="(item, i) in section.epa_pricing.pricing_items" :key="i" class="block-epa__left-pricing-col column is-6">
              <div class="block-epa__pricing-item">
                <span class="block-epa__pricing-item-label lead-label has-pad-right-m">
                  <strong>{{ item.title }}</strong>
                </span>
                <span class="title is-1 is-strong">
                  {{ item.single_price }}
                </span>
              </div>
            </div>
          </div>
          <bard
            v-if="section.epa_pricing.left_description"
            lead
            :as-content="true"
            :content="section.epa_pricing.left_description"
            class="has-marg-top-m"
          />
          <div v-if="section.epa_pricing.left_button_link" class="block-epa__left-button-wrapper has-marg-top-m">
            <pt-button v-bind="getButtonBindings(section.epa_pricing, 'left_button_')">
              {{ section.epa_pricing.left_button_label }}
            </pt-button>
          </div>
        </div>
        <div class="block-epa__inner-right">
          <h3 class="title is-3 is-strong">
            {{ section.epa_pricing.right_title }}
          </h3>
          <div
            v-if="section.epa_pricing.pricing_items && section.epa_pricing.pricing_items.length"
            class="block-epa__right-pricing has-marg-top-s columns is-multiline"
          >
            <div v-for="(item, i) in section.epa_pricing.pricing_items" :key="i" class="block-epa__right-pricing-col column is-12">
              <div class="block-epa__pricing-item">
                <span class="lead-label has-pad-right-m">
                  <strong>{{ item.title }}</strong>
                </span>
                <span class="title is-1 is-strong">
                  {{ item.couple_price }}
                </span>
              </div>
            </div>
          </div>
          <div v-if="section.epa_pricing.right_button_link" class="block-epa__left-button-wrapper has-marg-top-m">
            <pt-button v-bind="getButtonBindings(section.epa_pricing, 'right_button_')">
              {{ section.epa_pricing.right_button_label }}
            </pt-button>
          </div>
          <bard
            v-if="section.epa_pricing.right_description"
            small
            :as-content="true"
            :content="section.epa_pricing.right_description"
            class="has-marg-top-m"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useButtons } from '~/composables/buttons';
import PtButton from '~/components/global/elements/PtButton.vue';
import Bard from '~/components/content/Bard.vue';
import { useSectionProps } from '~/composables/blocks';

defineProps(useSectionProps());

const { getButtonBindings } = useButtons();
</script>
