<template>
  <div class="team-member-item">
    <div class="team-member-item__image-container">
      <picture-basic
        v-if="teamMember?.image"
        v-bind="
          getPictureBindings(teamMember.image, {
            ratio: 1,
            contain: false,
            preset: 'team',
          })
        "
        class="team-member-item__image"
      />
      <div v-else class="team-member-item__image-blank" />
    </div>
    <hr class="team-member-item__line" />
    <h4 v-if="teamMember.title" class="team-member-item__name title is-3 is-up">
      {{ teamMember.title }}
    </h4>
    <div v-if="teamMember.subtitle" class="team-member-item__role title is-up is-strong has-marg-top-xxs">
      {{ teamMember.subtitle }}
    </div>
    <div v-if="teamMember.email" class="team-member-item__email has-marg-top-xs">
      <pt-icon-mail />
      <a :href="emailHref" class="link-primary is-3">
        <span class="link-primary__label">
          {{ teamMember.email }}
        </span>
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatEmailHref } from '@/utils/formatters';
import { useImages } from '~/composables/images';
import PtIconMail from '~/components/global/icons/PtIconMail.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';

const props = defineProps({
  teamMember: {
    type: Object,
    required: true,
  },
});

const { getPictureBindings } = useImages();

const emailHref = computed(() => formatEmailHref(props.teamMember.email));
</script>
