<template>
  <div class="block-double-features">
    <div class="block-double-features__container">
      <div class="block-double-features__inner">
        <pt-section-header
          :title="section.title"
          title-size="3"
          :title-tag="titleTag"
          :description="section.description"
          class="container is-narrow has-marg-bottom-s"
        />
        <features-double :items="features" />
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script setup lang="ts">
import FeaturesDouble from '@/components/global/components/features/FeaturesDouble';
import { getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();

const features = computed(() => props.section.features);

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h3'));
</script>
