<template>
  <div class="pricing-cards has-marg-bottom-m">
    <div class="columns is-multiline is-variable is-1 is-centered">
      <div v-for="(item, index) in primaryItems" :key="`p-${index}`" class="pricing-cards__col column is-6-tablet is-4-desktop">
        <pt-card fullheight border="grey" has-pad="xs">
          <pricing-card-header :item="item" with-content :bg="headerColor(item)" />
          <div class="has-marg-top-m has-marg-bottom-m">
            <bard v-if="item.description_column_1" :as-content="true" :content="item.description_column_1" class="has-marg-bottom-l" />
            <bard v-if="item.description_column_2" :as-content="true" :content="item.description_column_2" class="has-marg-bottom-l" />
            <bard v-if="item.description_column_3" :as-content="true" :content="item.description_column_3" class="has-marg-bottom-l" />
          </div>
        </pt-card>
      </div>
      <div v-for="(item, index) in secondaryItems" :key="`s-${index}`" class="pricing-cards__col column is-12-mobile is-12-tablet">
        <pt-card border="grey" has-pad="xs">
          <div class="columns is-multiline">
            <div class="column is-12-tablet is-3-widescreen">
              <pricing-card-header
                :item="item"
                with-content
                always-on-left
                fullheight
                :bg="headerColor(item)"
                :button-bindings="getButtonBindings(item, 'secondary_button_')"
              />
            </div>
            <div class="column is-12-tablet is-9-widescreen">
              <div class="pricing-cards__secondary-content has-pad-top-m has-pad-bottom-m has-pad-left-m has-pad-right-m">
                <div v-if="item.description_header" class="title is-4 is-strong">
                  {{ item.description_header }}
                </div>

                <div class="columns has-marg-top-m">
                  <div class="column is-4">
                    <bard v-if="item.description_column_1" :as-content="true" :content="item.description_column_1" />
                  </div>
                  <div class="column is-4">
                    <bard v-if="item.description_column_2" :as-content="true" :content="item.description_column_2" />
                  </div>
                  <div class="column is-4">
                    <bard v-if="item.description_column_3" :as-content="true" :content="item.description_column_3" />
                  </div>
                </div>

                <div class="pricing-cards__secondary-content-button-wrapper">
                  <pt-button v-bind="getButtonBindings(item, 'secondary_button_')">
                    {{ item.secondary_button_label }}
                  </pt-button>
                </div>
              </div>
            </div>
          </div>
        </pt-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import PtCard from '~/components/global/elements/PtCard.vue';
import PricingCardHeader from '~/components/global/components/pricing/PricingCardHeader.vue';
import Bard from '~/components/content/Bard.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import { useButtons } from '~/composables/buttons';

const props = defineProps({
  pricingItems: {
    type: Array,
    required: true,
  },
});

const { getButtonBindings } = useButtons();

const primaryItems = computed(() => props.pricingItems.filter((item, i) => i < 3));
const secondaryItems = computed(() => (props.pricingItems[3] ? [props.pricingItems[3]] : []));
const headerColor = (item) => `${getSelectValue(item.colour) || 'pink'}-light`;
</script>
