<template>
  <div class="block-hero-cta">
    <div class="block-hero-cta__inner container">
      <div class="block-hero-cta__cols">
        <div class="block-hero-cta__col is-content">
          <div class="block-hero-cta__content">
            <component :is="titleTag" v-if="section.title" class="block-hero-cta__title title is-1">
              {{ section.title }}
            </component>
            <bard
              v-if="section.description"
              :as-content="true"
              :content="section.description"
              class="block-hero-cta__description has-marg-top-m is-lead"
            />
          </div>
        </div>
        <div class="block-hero-cta__col is-menu">
          <div class="block-hero-cta__buttons">
            <pt-menu-buttons :buttons="buttons" />
          </div>
        </div>
      </div>
    </div>
    <pt-blob v-if="getDisplayBlob(section)" v-bind="getBlobBindings(section)" />
  </div>
</template>

<script setup lang="ts">
import { ensureArrayValue, getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useBlob } from '~/composables/blob';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import Bard from '~/components/content/Bard.vue';
import PtMenuButtons from '~/components/global/elements/PtMenuButtons.vue';

const props: SectionProps = defineProps(useSectionProps());
const { getDisplayBlob, getBlobBindings } = useBlob();

const buttons = computed(() => ensureArrayValue(props.section.menu_buttons));

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h1'));
</script>
