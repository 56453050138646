<template>
  <div
    v-scroll-spy="{
      allowNoActive: false,
      sectionSelector: '.block-anchor > a',
      enabled: hasAnchors,
      offset: 5,
    }"
    class="section"
    :class="{ 'has-anchors': hasAnchors, 'has-anchors-inited': hasAnchorsInited }"
  >
    <render-block v-for="(section, index) in sections" :key="`section-${index}`" :section="section" :section-index="index" />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, type PropType } from 'vue';
import RenderBlock from '~/components/content/RenderBlock.vue';
import type { LooseObject } from '~/types/generic';

const props = defineProps({
  sections: {
    type: Array as PropType<LooseObject[]>,
    required: true,
  },
});

const hasAnchorsInited = ref(false);

const hasAnchors = computed(() => {
  return props.sections.filter((s) => s.type === 'block_anchor').length > 0;
});

onMounted(() => {
  nextTick(() => {
    hasAnchorsInited.value = true;
  });
});
</script>
