<template>
  <div
    class="block-metrics"
    :class="{
      'is-3-items': list.length === 3,
      'is-2-items': list.length === 2,
      'is-1-items': list.length === 1,
      'has-slider': list.length > 3,
    }"
  >
    <div class="container">
      <div class="block-metrics__inner">
        <pt-section-header
          :title="section.title"
          :description="section.description"
          :title-tag="titleTag"
          class="block-metrics__header has-marg-bottom-m"
        />
        <div class="block-metrics__list">
          <div v-for="(item, index) in list" :key="`${item.title}-${index}`" class="block-metrics__card">
            <h6 class="block-metrics__card-title">
              {{ item.title }}
            </h6>
            <p class="block-metrics__card-description">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <pt-slider v-if="list.length > 3" name="" :items="list" class="block-metrics__slider container">
      <template #default="item">
        <div class="block-metrics__card">
          <h6 class="block-metrics__card-title">
            {{ item.item.title }}
          </h6>
          <p class="block-metrics__card-description">
            {{ item.item.description }}
          </p>
        </div>
      </template>
    </pt-slider>
  </div>
</template>

<script lang="ts" setup>
import { getSelectValue } from '@/utils/blocks';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import PtSlider from '~/components/global/elements/PtSlider.vue';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';

const props: SectionProps = defineProps(useSectionProps());

const list = computed(() => props.section.metrics || []);

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));
</script>
