<template>
  <div class="block-contact">
    <div class="block-contact__inner container is-narrow">
      <div class="columns is-variable is-6-tablet is-8-desktop is-vcentered">
        <div class="column is-6">
          <picture-basic v-if="section.image" v-bind="imageBindings" />
        </div>
        <div class="column is-6">
          <div class="block-contact__content">
            <contact-info
              :title="section.title"
              :title-tag="titleTag"
              :description="section.description"
              :overseas-title="section.overseas_title"
              :marketing-title="section.marketing_title"
              :pobox-title="section.pobox_title"
              :live-chat-title="section.live_chat_title"
              :media-title="section.media_title"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ContactInfo from '~/components/global/components/contact/ContactInfo.vue';
import { getSelectValue } from '@/utils/blocks';
import { getImageRatio } from '@/utils/images';
import type { SectionProps } from '~/types/components';
import { useSectionProps } from '~/composables/blocks';
import { useImages } from '~/composables/images';
import PictureBasic from '~/components/global/images/PictureBasic.vue';

const props: SectionProps = defineProps(useSectionProps());
const { getPictureBindings } = useImages();

const titleTag = computed(() => getSelectValue(props.section.title_size_select, 'value', 'h2'));

const imageBindings = computed(() =>
  getPictureBindings(props.section.image, {
    ratio: getImageRatio(getSelectValue(props.section.image_ratio, 'value')),
    loading: props.sectionIndex ? 'lazy' : null,
    preset: 'side',
  })
);
</script>
