<template>
  <div class="content-row-image-text">
    <div class="content-row-image-text__inner">
      <div class="content-row-image-text__cols columns is-variable is-6-tablet is-8-desktop">
        <div class="content-row-image-text__col column is-6 is-image">
          <bard :content="row.text_1" :as-content="true" :primary-links="primaryLinks" />
        </div>
        <div class="content-row-image-text__col column is-6 is-text">
          <bard :content="row.text_2" :as-content="true" :primary-links="primaryLinks" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ContentRowProps } from '~/types/components';
import { useContentRowProps } from '~/composables/blocks';
import Bard from '~/components/content/Bard.vue';

const props: ContentRowProps = defineProps(useContentRowProps());
const primaryLinks = computed(() => !props.row.secondary_links);
</script>
