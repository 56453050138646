<template>
  <div class="features-double">
    <div class="features-double__bgs" role="presentation">
      <span v-for="(item, index) in items" :key="`bg-${index}`" class="features-double__bg" :class="getBgClass(item)" role="presentation" />
    </div>
    <div class="features-double__inner container is-features">
      <div class="features-double__cols is-header">
        <div v-for="(item, index) in items" :key="`feature-${index}`" class="features-double__col">
          <feature-double :item="item" header-only class="features-double__item is-header" />
        </div>
      </div>
      <div class="features-double__cols is-full">
        <div v-for="(item, index) in items" :key="`feature-${index}`" class="features-double__col">
          <span class="features-double__item-bg" :class="getBgClass(item)" role="presentation" />
          <feature-double :item="item" class="features-double__item is-full" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import FeatureDouble from '@/components/global/components/features/FeatureDouble';
import { getSelectValue } from '@/utils/blocks';

defineProps({
  items: {
    type: Array,
    required: true,
  },
});

const getBgClass = (item) => {
  const background = getSelectValue(item.background, 'value', 'peach');

  return [`has-bg-${background}-light`];
};
</script>
